<div class="modal-header">
  <h4 class="modal-title">Update address information</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close(false)">
    <span>&times;</span>
  </button>
</div>
<div class="modal-body">

  <div [formGroup]="addressForm" class="address-form">
    <div class="form-group row">
      <label class="col-sm-3 control-label" for="postalCode">Postal code<sup class="text-danger">*</sup></label>
      <div class="col-sm-9">
        <input
          class="form-control"
          id="postalCode"
          formControlName="postalCode"
          autocomplete="none"
        >
      </div>
    </div>

    <input-with-select
      [control]="addressForm.controls.city"
      [options]="$cityOptions"
      label="City"
      inputName="city"
      [isRequired]="true"
    ></input-with-select>

    <input-with-select
      [control]="addressForm.controls.street"
      [options]="$streetOptions"
      label="Street"
      inputName="street"
      [isRequired]="false"
    ></input-with-select>

    <div class="form-group row">
      <label class="col-sm-3 control-label" for="houseNumber">House number<sup class="text-danger">*</sup></label>
      <div class="col-sm-9">
        <input class="form-control" id="houseNumber" formControlName="houseNumber">
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-3 control-label" for="apartmentNumber">Apartment number</label>
      <div class="col-sm-9">
        <input class="form-control" id="apartmentNumber" formControlName="apartmentNumber">
      </div>
    </div>
  </div>


</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="activeModal.close(false)">Close</button>
  <button type="submit" class="btn btn-outline-danger" [disabled]="addressForm.invalid" (click)="submitForm()">Save</button>
</div>


