<aside class="container-fluid pt-3 pb-3 pl-1">
  <div class="col-md-12 ml-0 pl-0">
    <server-refresh (refresh)="refresh()"></server-refresh>
    <span class="mr-2 ml-3">
            <button appRequirePermission="admin.all,loan-sale-partner.edit"
                    class="btn btn-success btn-sm"
                    (click)="navigateTo(0)">
              <bi name="pencil-square"></bi>
              Add Partner
            </button>
      </span>
    <span class="mr-2 ml-3">
            <button appRequirePermission="admin.all,loan-sale-partner.edit"
                    class="btn btn-success btn-sm"
                    (click)="navigateTo(1)">
              <bi name="pencil-square"></bi>
              Edit Partners
            </button>
      </span>
  </div>
</aside>
<server-table>
  <table list-table class="table table-striped table-hover mt-1">
    <thead class="text-light bg-primary">
    <tr>
      <th></th>
      <th>Id</th>
      <th>Name</th>
      <th>Bank account</th>
      <th>Enabled auto assigment</th>
      <th>Max portfolio amount</th>
      <th>Weight</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of response?.results">
      <td></td>
      <td>{{item.id}}</td>
      <td>{{item.name}}</td>
      <td>{{item.bankAccount}}</td>
      <td>{{item.enabledForPortfolioAutoAssignment}}</td>
      <td>{{item.maxPortfolioAmount}}</td>
      <td>{{item.weight}}</td>
      <td></td>
    </tr>
    </tbody>
  </table>
</server-table>
