import { Component, Input, OnInit } from '@angular/core';
import { CountrySpecific } from '../../../../../../libs/commons/src/lib/services/country-specific';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertsService, BasicComponent } from '@backoffice-monorepo/commons';
import { PlPiTransferService } from '../../services/pl-pi-transfer.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoansRefreshService } from '../../../../../../libs/core/src/lib/modules/loans/services/loans-refresh-services';
import { takeUntil } from 'rxjs/operators';
import { ConfirmBankTransferRequest, SubmitBankTransferRequest } from '../../api/pl-pi-transfer';
import { ChargeableOperationInfoRequest, ChargeableOperationInfoResponse } from '../../modules/tariff/api/tariff-config';
import { PlPiClientService } from '../../services/pl-pi-client.service';

@Component({
  selector: 'backoffice-monorepo-pl-pi-transfer-outgoing-add',
  templateUrl: './pl-pi-transfer-outgoing-add.component.html',
  styleUrls: ['./pl-pi-transfer-outgoing-add.component.css']
})
export class PlPiTransferOutgoingAddComponent extends BasicComponent implements OnInit, CountrySpecific {

  @Input() loanId: number;
  @Input() clientId: number;
  transferAddForm: FormGroup;
  otpConfirmForm: FormGroup;
  showConfirmationForm = false;
  confirmationChannel = "SMS";
  transferPaymentId: number;
  enableAddButton = false;
  chargeableInfo: ChargeableOperationInfoResponse;

  constructor(
    public activeModal: NgbActiveModal,
    private alertService: AlertsService,
    private transferService: PlPiTransferService,
    private formBuilder: FormBuilder,
    private loanRefreshService: LoansRefreshService,
    private clientService: PlPiClientService
  ) {
    super();
  }

  ngOnInit(): void {
    this.transferAddForm = this.formBuilder.group({
      amount: ['', Validators.required],
    });
    this.otpConfirmForm = this.formBuilder.group({
      confirmationCode: ['', [Validators.required, Validators.maxLength(4), Validators.minLength(4)]]
    });
  }

  submitTransferForm() {
    const outgoingTransferRequest: SubmitBankTransferRequest = {
      amount: this.transferAddForm.value.amount,
      confirmationChannel: this.confirmationChannel
    }
    this.transferService.submitBankTransfer(this.loanId, outgoingTransferRequest)
      .pipe(
        takeUntil(this.$destroy)
      ).subscribe(response => {
      this.transferPaymentId = response.paymentId;
      this.showConfirmationForm = true;
      this.alertService.notifySuccess(`Outgoing transfer added`);
    })
  }

  confirmOtp() {
    const confirmTransferRequest: ConfirmBankTransferRequest = {
      otpCode: this.otpConfirmForm.value.confirmationCode,
      paymentId: this.transferPaymentId,
      confirmationChannel: this.confirmationChannel
    }
    this.transferService.confirmBankTransfer(this.loanId, confirmTransferRequest)
      .pipe(
        takeUntil(this.$destroy)
      ).subscribe(() => {
      this.alertService.notifySuccess(`Outgoing transfer confirmed`);
      this.loanRefreshService.reloadLoanModel();
      this.activeModal.close();
    })
  }

  getChargeableOperationInfo() {
    const chargeableOperationInfoRequest: ChargeableOperationInfoRequest = {
      feeType: "TAF_TRANSFER_ORDER_HELPLINE",
      transactionAmount: this.transferAddForm.value.amount
    }
    this.clientService.isOperationChargeable(this.clientId, chargeableOperationInfoRequest)
      .pipe(
        takeUntil(this.$destroy)
      ).subscribe(response => {
        this.enableAddButton = true;
        this.chargeableInfo = response;
    })
  }
}
