import { Component, Input, OnInit } from '@angular/core';
import { PlPiTariffClientService } from '../../modules/tariff/services/pl-pi-tariff-client.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { take, takeUntil } from 'rxjs/operators';
import { PlPiClientService } from '../../services/pl-pi-client.service';
import { FeeType } from '@twino/backoffice-api';
import { AlertsService, BasicComponent, Utils } from '@backoffice-monorepo/commons';
import { ChargeableOperationInfoRequest, MonthlyFeeDetails, TariffChargeableOperationFee } from '../../modules/tariff/api/tariff-config';
import { CardIssueType, CreateCardConfirmationRequest } from '../../api/client.card';
import { FormBuilder, Validators } from '@angular/forms';
import { PlPiClientCardService } from '../../services/pl-pi-client-card.service';

@Component({
  selector: 'backoffice-monorepo-pl-pi-client-card-add',
  templateUrl: './pl-pi-client-card-add.component.html',
  styleUrls: ['./pl-pi-client-card-add.component.scss']
})
export class PlPiClientCardAddComponent extends BasicComponent implements OnInit {

  @Input() clientId: number;
  @Input() cardType: CardIssueType;
  chargeableFees: TariffChargeableOperationFee[] = [];
  monthlyFees: MonthlyFeeDetails;
  chargeableFeeNoTariff: number;
  class = "alert-danger";
  icon = "exclamation-octagon-fill";
  utils = Utils;
  issuance_1st_year: FeeType;
  monthly_from_second_year: FeeType;
  showConfirmationCodeInput = false;
  confirmForm = this.formBuilder.group({
    confirmationCode: [
      '',
      [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4)
      ]
    ]
  });

  constructor(
    public activeModal: NgbActiveModal,
    private tariffClientService: PlPiTariffClientService,
    private clientService: PlPiClientService,
    private clientCardService: PlPiClientCardService,
    private formBuilder: FormBuilder,
    private alertService: AlertsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.issuance_1st_year =  (this.cardType === CardIssueType.PLASTIC) ? FeeType.PC_ISSUANCE_1ST_YEAR : FeeType.VC_ISSUANCE_1ST_YEAR;
    this.monthly_from_second_year = (this.cardType === CardIssueType.PLASTIC) ? FeeType.PC_MONTHLY_FROM_2ND_YEAR : FeeType.VC_MONTHLY_FROM_2ND_YEAR;
    this.getTariffs();
  }

  getTariffs() {
    this.tariffClientService.getActiveTariff(this.clientId).pipe(
      take(1)
    ).subscribe(response => {
      this.chargeableFees = response.chargeableOperationsFees[this.issuance_1st_year].rangedChargeableOperationsFees;
      this.monthlyFees = response.monthlyFees[this.monthly_from_second_year];
    }, () => {
      const request: ChargeableOperationInfoRequest = {
        feeType: this.issuance_1st_year
      }
      this.clientService.isOperationChargeable(this.clientId, request).pipe(take(1)).subscribe(response => {
        this.chargeableFeeNoTariff = response.amount;
      })
    })
  }

  submitForm() {
    if(!this.showConfirmationCodeInput) {
      this.clientCardService.createCard(this.clientId)
        .pipe(
          takeUntil(this.$destroy)
        )
        .subscribe(response => {
          this.showConfirmationCodeInput = true;
          this.confirmForm.get('confirmationCode').enable();
        });
    } else {
      const request: CreateCardConfirmationRequest = {
        confirmationCode: this.confirmForm.get('confirmationCode').value,
        cardIssueType: this.cardType
      }
      this.clientCardService.confirmCardCreation(this.clientId, request)
        .pipe(
          takeUntil(this.$destroy)
        )
        .subscribe(() => {
          this.alertService.notifySuccess(`Card ${this.cardType} has been created successfully.`);
          this.activeModal.close(true);
        })
    }
  }
}
