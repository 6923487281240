import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoanSaleRefreshService implements OnDestroy  {

  public reloadLoanSaleList$ = new Subject<void>();


  reloadLoanSaleList() {
    this.reloadLoanSaleList$.next();
  }


  ngOnDestroy(): void {
    this.reloadLoanSaleList$.unsubscribe();
  }
}
