import { Component, Input, OnInit } from '@angular/core';
import {
  AlertsService,
  BasicComponent,
  LocalesService,
  MessageTemplateService,
  ProductConfigService,
  tinyMceEditorConfig
} from '@backoffice-monorepo/commons';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  getCommunicationTypeSuffixList,
  MessageTemplate,
  MessageTemplateRequest,
  NotificationCommunicationType,
  ProductConfig
} from '@twino/backoffice-api';
import { forkJoin, Observable, of } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { map, mergeMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'message-template-add',
  templateUrl: './message-template-add.component.html',
  styleUrls: ['./message-template-add.component.scss']
})
export class MessageTemplateAddComponent extends BasicComponent implements OnInit {

  @Input() communicationType: NotificationCommunicationType | null;
  @Input() locale: string | null;
  @Input() key: string | null;
  @Input() brand: string | null;

  messageTemplateAddForm: FormGroup;
  locales: Observable<Array<string>>;
  activeTabItem = 0;
  useTinyMCE: Array<boolean> = [];
  public productBrandsList$: Observable<string[]>

  editorConfig = tinyMceEditorConfig;

  constructor(
    private alertService: AlertsService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private messageTemplatesService: MessageTemplateService,
    private localesService: LocalesService,
    private productConfig: ProductConfig,
    private productConfigService: ProductConfigService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.locales = this.localesService.getLocales();
    this.productBrandsList$ = this.productConfigService.listBrands();
    this.messageTemplateAddForm = this.formBuilder.group({
      templateTabs: this.formBuilder.array([])
    });

    getCommunicationTypeSuffixList(this.communicationType)
      .forEach((suffix) => {
        const formGroupTemplate = this.createForm();
        const messageTemplate = {
          key: (this.key ? this.key : '') + suffix,
          message: '',
          locale: this.locale || '',
          isDisabled: false,
          brand: this.brand || ''
        }

        formGroupTemplate.setValue(messageTemplate);
        this.formControlsMessageTemplateTabs.push(formGroupTemplate);
        if (suffix.includes(this.productConfig.httpTemplateKeyMarker)) {
          this.useTinyMCE[this.formControlsMessageTemplateTabs.length - 1] = true;
        }
      });
  }

  getName(): string {
    return `Create message template`;
  }

  createForm(): FormGroup {
    return this.formBuilder.group({
      key: ['', Validators.required],
      message: ['', Validators.required],
      locale: '',
      isDisabled: false,
      brand: [''],
    });
  }

  get formControlsMessageTemplateTabs() {
    return this.messageTemplateAddForm.get('templateTabs') as FormArray;
  }

  createMessageTemplate(messageTemplateRequest: MessageTemplateRequest): Observable<unknown> {
    return this.messageTemplatesService.createMessageTemplate(messageTemplateRequest)
      .pipe(
        tap(() => this.alertService.notifySuccess(`Message template ${messageTemplateRequest.key} is added`)),
        takeUntil(this.$destroy)
      )
  }

  submitForm() {
    const templateRequest: Observable<never>[] = this.messageTemplateAddForm.value.templateTabs
      .map(it => of(it).pipe(
        map((template: MessageTemplate) => new MessageTemplateRequest(template.key, template.locale, template.isDisabled, template.message, template.brand)),
        mergeMap(messageTemplateRequest => this.createMessageTemplate(messageTemplateRequest))
      ))

    forkJoin(templateRequest).pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => this.activeModal.close())
  }

  syncLocales($event) {
    const templateTabsFormControls = this.messageTemplateAddForm.get('templateTabs') as FormArray;
    templateTabsFormControls.controls.forEach(formControl => {
      formControl.get('locale').setValue($event.target.value);
    })
  }

  switchTextarea(index) {
    this.useTinyMCE[index] = !this.useTinyMCE[index];
  }
}
