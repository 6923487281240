<aside class="container-fluid pt-3 pb-3 pl-1">
  <div class="col-md-12 ml-0 pl-0">
    <server-refresh (refresh)="refresh()"></server-refresh>
  </div>
</aside>
<ng-container *ngIf="response$ | async as response">
  <table class="table table-striped table-hover mt-1">
    <thead class="text-light bg-primary">
    <tr>
      <th></th>
      <th>Loan ID</th>
      <th>Total amount</th>
      <th>DPD</th>
      <th>Agreement type</th>
      <th>Move</th>
      <th>Remove</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of response?.results">
      <td></td>
      <td>{{item.loanId}}</td>
      <td>{{item.totalAmount}}</td>
      <td>{{item.dpd}}</td>
      <td>{{item.agreementType}}</td>
      <td>
        <button appRequirePermission="admin.all,loan-sale.edit"
                class="btn btn-primary btn-sm"
                (click)="moveToAnotherPortfolio(item)">
          move
        </button>
      </td>
      <td>
        <button appRequirePermission="admin.all,loan-sale.edit"
                class="btn btn-danger btn-sm"
                (click)="removeFromPortfolio(item.loanId)">
          Remove
        </button>
      </td>
      <td></td>
    </tr>
    </tbody>
  </table>

  <nav class="container-fluid row">
    <div class="col-md-10">
      <pagination [totalRecords]="response.totalRecords"
                  [totalPages]="response.totalPages"
                  [currentPage]="response.currentPage"
                  [itemsPerPage]="searchQuery?.pageSize"
                  (setPage)="refresh($event)"
      ></pagination>
    </div>
    <div class="col-md-2 text-right">Total record(s): {{response?.totalRecords}}</div>
  </nav>
</ng-container>




