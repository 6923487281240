
<div class="modal-header" cdkDragHandle>
  <h4 class="modal-title">Mark loan #{{loanId}} as {{mode}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close(false)">
    <span>&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="form-group row">
    <label for="onTime" class="col-sm-4 control-label">Date time<sup class="text-danger">*</sup></label>
    <div class="form-inline">
      <div class="form-group">
        <div class="input-group">
          <date-time-picker
            [(ngModel)]="dateTimeValue"
            name="dp"
            id="onTime"
          ></date-time-picker>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="activeModal.close(false)">Close</button>
  <button type="submit" class="btn btn-outline-danger" [disabled]="!dateTimeValue" (click)="submitForm()">Save</button>
</div>



