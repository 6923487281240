import { Component, Input, OnInit } from '@angular/core';
import { BasicComponent, SalePartnerService, TwinoDateAdapterService } from '@backoffice-monorepo/commons';
import { NgbActiveModal, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { take, takeUntil } from 'rxjs/operators';
import { LoanSalePortfolioService } from '../../../../../../../commons/src/lib/services/loan-sale-portfolio.service';
import { LoanSalePartnerResponse, LoanSalePortfolio, SearchQueryBuilder, SellLoanPortfolioRequest } from '@twino/backoffice-api';

@Component({
  selector: 'backoffice-monorepo-loan-sell-package',
  templateUrl: './loan-sell-package.component.html',
  styleUrls: ['./loan-sell-package.component.scss'],
  providers: [
    {provide: NgbDateAdapter, useClass: TwinoDateAdapterService}
  ]
})
export class LoanSellPackageComponent extends BasicComponent implements OnInit {

  @Input() portfolio: LoanSalePortfolio;
  sellForm: FormGroup;
  partners: LoanSalePartnerResponse[] | undefined;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private salePartnerService: SalePartnerService,
    private loanSalePortfolioService: LoanSalePortfolioService
  ) {
    super();
  }

  ngOnInit(): void {
    this.sellForm = this.formBuilder.group({
      saleAmount: [this.portfolio.saleAmount, Validators.required],
      onDate: ['', Validators.required],
      partnerId: ['', Validators.required]
    });

    const searchQuery = new SearchQueryBuilder()
      .withPageSize(20)
      .withPage(1)
      .build();
    this.salePartnerService.find(searchQuery).pipe(
      take(1)
    ).subscribe(response => {
      this.partners = response.results;
    })
  }

  submitForm() {
    const request: SellLoanPortfolioRequest = {
      onDate: this.sellForm.value.onDate,
      partnerId: this.sellForm.value.partnerId,
      portfolioId: this.portfolio.id,
      saleAmount: this.sellForm.value.saleAmount,
    }
    this.loanSalePortfolioService.sellPortfolio(request).pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
      this.activeModal.close(true);
    })
  }
}
