import { TermItem } from '@twino/backoffice-api';

export interface CreateRestructuringIntentionRequest {
  loanId: number
  firstRepaymentDayOfMonth: number
  term: TermItem
}

export interface CalculateRestructuringMonthlyAmountRequest {
  openAmount: number
  term: TermItem
}

export interface CalculateRestructuringMonthlyAmountResponse {
  monthlyAmount: number
}

export interface RestructuringAvailabilityResponse {
  isFlaggedForRestructuring: boolean,
  loanCanBeRestructured: boolean,
  lastLoanTerminationLetterSendDateTime: string
}

export enum RestructuringStateType {
  RESTRUCTURING_NOT_AVAILABLE = 'RESTRUCTURING_NOT_AVAILABLE',
  RESTRUCTURING_AVAILABLE = 'RESTRUCTURING_AVAILABLE',
  RESTRUCTURING_INTENTION_ACTIVE = 'RESTRUCTURING_INTENTION_ACTIVE',
}

export interface RestructuringIntentionResponse {
  id: number
  loanId: number
  amountToRestructure: number
  loanLength: TermItem
  creationDate: Date
  expirationDate: Date
  activationAmount: number
  monthlyPaymentAmount: number
  firstRepaymentDate: Date
  restructuringActivated: boolean
  tolerance: number
}

export interface RestructuringStateResponseBackoffice {
  stateType: RestructuringStateType
  loanOpenAmount?: number
  restructuringMinTermLength?: TermItem
  restructuringMaxTermLength?: TermItem
  monthlyPayment?: number
  activationAmount?: number
}
