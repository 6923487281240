import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from './errors.service';
import {
  LoanPreSaleSearchQuery,
  LoanSaleDataResponseSearchResult,
  LoanSaleSearchQuery
} from '@backoffice-monorepo/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoanSaleDataService extends BaseService {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  list(searchQuery: LoanSaleSearchQuery): Observable<LoanSaleDataResponseSearchResult> {
    return this.post(`/api/loan-sale/data/search`, searchQuery);
  }

  setPreSellStatus(searchQuery: LoanPreSaleSearchQuery): Observable<unknown> {
    return this.post(`/api/loan-sale/data/pre-sell`, searchQuery);
  }

  preSell(loanIds: number[]): Observable<unknown> {
    return this.post(`/api/loan-sale/portfolio`, {loanIds});
  }

  deleteLoanFromLoanSaleData(id: number): Observable<unknown> {
    return this.delete(`/api/loan-sale/data/loan/${id}`, {});
  }

  generatePreviewPreSellReport(request: LoanSaleSearchQuery): Observable<Blob> {
    return this.post(`/api/loan-sale/data/preview-pre-sell-report`, request, {'responseType': 'blob'});
  }
}
