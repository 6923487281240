import { Component, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, takeUntil } from 'rxjs/operators';
import { LoanSalePortfolioService } from '@backoffice-monorepo/commons';
import { BasicComponent } from '@backoffice-monorepo/commons';
import { of } from 'rxjs';
import { LoanSaleDataResponse } from '@twino/backoffice-api';

@Component({
  selector: 'backoffice-monorepo-change-loan-portfolio',
  templateUrl: './change-loan-portfolio.component.html',
  styleUrls: ['./change-loan-portfolio.component.scss']
})
export class ChangeLoanPortfolioComponent extends BasicComponent {
  portfolioId: FormControl = new FormControl('', Validators.required);
  @Input() loan: LoanSaleDataResponse;
  constructor(
    public activeModal: NgbActiveModal,
    private loanSalePortfolioService: LoanSalePortfolioService
  ) {
    super();

  }

  submit() {
    this.loanSalePortfolioService
      .moveLoansToAnotherPortfolio({ portfolioId: this.portfolioId.value, loanIds: [this.loan.loanId] })
      .pipe(
        takeUntil(this.$destroy),
        catchError(() => {
          this.activeModal.close(false);
          return of(null)
        })
    ).subscribe(() => {
      this.activeModal.close(true);
    })
  }

}
