<div class="modal-header">
  <h4 class="modal-title">Create intention</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close(false)">
    <span>&times;</span>
  </button>
</div>
<div class="modal-body">
<div class="payment-container" [formGroup]="form">

  <div class="payment-box  border-radius container-background-color">
    <div class="payment-details  border-radius">
      <p>Loan open amount</p>
      <h3 class="font-size-40">{{state.loanOpenAmount}}</h3>
      <p>Activation amount</p>
      <h3 class="font-size-40">{{state.activationAmount}}</h3>
    </div>

    <div class="custom-slider">
      <input type="range"
             id="installmentsNumber"
             formControlName="installmentsNumber"
             [min]="state.restructuringMinTermLength.value"
             [max]="state.restructuringMaxTermLength.value"
             [step]="step"
             (change)="sliderMove()"/>
      <h6 class="ml-2">
          Min: {{state.restructuringMinTermLength.value}} {{state.restructuringMinTermLength.unit}}
        | Max: {{state.restructuringMaxTermLength.value}} {{state.restructuringMaxTermLength.unit}}
      </h6>
    </div>

    <div class="slider-section" >
      <div class="slider-group d-flex">
        <div class="d-flex flex-column">
          <span>Monthly payment</span>
          <span>{{monthlyPayment}}</span>
        </div>
        <div class="d-flex flex-column">
          <span>Installments number</span>
          <span>{{form.controls.installmentsNumber.value}}</span>
        </div>
        <div class="d-flex flex-column align-items-center ">
          <span>First repayment date</span>
          <select formControlName="firstRepaymentDate"  class="form-control day-input form-control-sm">
            <option value="1">1</option>
            <option value="10">10</option>
            <option value="25">25</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="activeModal.close(false)">Close</button>
  <button type="submit" class="btn btn-outline-danger"  (click)="createIntention()">Save</button>
</div>
