import { Component, Host, OnDestroy, OnInit } from '@angular/core';
import { ConfirmDialogueComponent, FilteringService, ListComponent } from '@backoffice-monorepo/commons';
import {
  FilterOperation,
  LoanSaleFileSignedUrlResponse,
  LoanSalePortfolio,
  LoanSalePortfolioStatus,
  SearchQueryAware,
  SearchQueryBuilder
} from '@twino/backoffice-api';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { tap } from 'rxjs/operators';
import { LoanSalePortfolioService } from '@backoffice-monorepo/commons';
import { LoanSellPackageComponent } from '../loan-sell-package/loan-sell-package.component';
import { Subscription } from 'rxjs';
import { LoanSaleRefreshService } from '../../services/loan-sale-refresh.service';

@Component({
  selector: 'backoffice-monorepo-loan-sale-data-list',
  templateUrl: './loan-sale-data-list.component.html',
  styleUrls: ['./loan-sale-data-list.component.scss'],
  providers: [
    {
      provide: FilteringService,
      useFactory: (listService: SearchQueryAware<LoanSalePortfolio>) => new FilteringService(listService),
      deps: [LoanSalePortfolioService]
    }
  ]
})
export class LoanSaleDataListComponent extends ListComponent<LoanSalePortfolio> implements OnInit, OnDestroy {
  reloadLoanSaleList: Subscription;
  loanSalePortfolioStatus = LoanSalePortfolioStatus;
  loanSaleFileSignedUrlResponse: LoanSaleFileSignedUrlResponse;
  showPassword = false;

  constructor(
    @Host() filteringService: FilteringService<LoanSalePortfolio>,
    protected router: Router,
    private loanSalePortfolioService: LoanSalePortfolioService,
    private loanSaleRefreshService: LoanSaleRefreshService,
    activatedRoute: ActivatedRoute,
    public modalService: NgbModal,
  ) {
    super(activatedRoute, filteringService);
  }

  ngOnInit(): void {
    const searchQueryBuilder = new SearchQueryBuilder()
      .addFilterData({
        enumClass: "eu.twino.loans.core.loansale.api.LoanSalePortfolioStatus",
        propertyName: "status",
        operation: FilterOperation.NOT_EQUALS,
        type: "enum",
        values: [LoanSalePortfolioStatus.CANCELED]
      })
    this.searchQuery = searchQueryBuilder.build();
    super.ngOnInit();
    this.reloadLoanSaleList = this.loanSaleRefreshService.reloadLoanSaleList$.subscribe(() => {
      this.refresh();
    })
  }

  navigateTo(id: string | number) {
    this.router.navigate([`/loan-sale/${id}`]);
  }

  getName() {
    return 'Loan sale';
  }

  preSellReportUrl(portfolioId: number): string {
    return  `/ws/api/loan-sale/portfolio/${portfolioId}/pre-sell-report/`;
  }

  downloadPortfolioDocumentsPackage(portfolioId: number) {
    return  `/ws/api/loan-sale/portfolio/${portfolioId}/portfolio-documents/`;
  }

  sellPortfolio(portfolio: LoanSalePortfolio) {
    const modalRef = this.modalService.open(LoanSellPackageComponent);
    modalRef.componentInstance.portfolio = portfolio;
    modalRef.result.then(
      (result) => {
        if (result === true) {
          this.refresh();
        }
      }
    );
  }

  revertPortfolioSelling(portfolio: LoanSalePortfolio) {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = "Revert portfolio";
    modalRef.componentInstance.content = "Are you sure you want to revert selling of this portfolio?";
    modalRef.result.then(
      (result) => {
        if (result === true) {
          this.loanSalePortfolioService.revertPortfolioSelling({portfolioId: portfolio.id})
            .subscribe(() =>  this.refresh())
        }
      }
    );
  }


  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  getLoanSaleFileSignedUrl(id: number) {
    this.showPassword = false;
    this.loanSalePortfolioService.getLoanSaleFileSignedUrl(id).pipe(
      tap(res => this.loanSaleFileSignedUrlResponse = res)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.reloadLoanSaleList.unsubscribe();
  }
}
