import { Component, Host, OnDestroy, OnInit } from '@angular/core';
import { ConfirmDialogueComponent, DebtConfigService, FilteringService, ListComponent } from '@backoffice-monorepo/commons';
import { ActivatedRoute, Router } from '@angular/router';
import { DebtStepConfig, FilterOperation, SearchQueryAware, SearchQueryBuilder } from '@twino/backoffice-api';
import { Subscription } from 'rxjs';
import { DebtConfigRefreshService } from '../../services/debt-config-refresh.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-debt-configs-list',
  templateUrl: './debt-configs-list.component.html',
  styleUrls: ['./debt-configs-list.component.scss'],
  providers: [
    {
      provide: FilteringService,
      useFactory: (listService: SearchQueryAware<DebtStepConfig>) => new FilteringService(listService),
      deps: [DebtConfigService]
    }
  ]
})
export class DebtConfigsListComponent extends ListComponent<DebtStepConfig> implements OnInit, OnDestroy {

  private reloadDebtConfigModel: Subscription;
  dcVersionCounter = Array;
  dcVersionFilterValue: string;

  constructor(
    @Host() filteringService: FilteringService<DebtStepConfig>,
    protected router: Router,
    activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private debtConfigService: DebtConfigService,
    private debtConfigRefreshService: DebtConfigRefreshService
  ) {
    super(activatedRoute, filteringService);
  }

  ngOnInit(): void {
    this.getDebtConfigsList();
    this.reloadDebtConfigModel = this.debtConfigRefreshService.reloadDebtConfigModel$.subscribe(
      () =>  this.getDebtConfigsList())
  }

  ngOnDestroy(): void {
    this.reloadDebtConfigModel.unsubscribe();
  }

  getName(): string {
    return 'Debt step configs';
  }

  navigateTo(id: number) {
    this.router.navigate([`/debt-step-configs/${id}`]);
  }

  getDebtConfigsList() {
    const searchQueryBuilder = new SearchQueryBuilder();
    if(this.dcVersionFilterValue) {
      searchQueryBuilder.addFilterData({
        propertyName: 'dcVersion',
        operation: FilterOperation.EQUALS,
        values: [this.dcVersionFilterValue]
      })
    }
    searchQueryBuilder.withSortCriterion({propertyName: "id", sortDirection: "DESC"});
    this.searchQuery = searchQueryBuilder.build();
    super.ngOnInit();
  }

  filterByVersion(value: string) {
    this.dcVersionFilterValue = value;
    this.getDebtConfigsList();
  }

  deleteConfig(id) {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = "Delete debt step configuration";
    modalRef.componentInstance.content = `Are you sure you want to delete debt step configuration #${id}?`;
    modalRef.result.then(
      (result) => {
        if (result === true) {
          this.debtConfigService.deleteDebtStepConfig(id).pipe(
            takeUntil(this.$destroy)
          )
            .subscribe(() => this.refresh());
        }
      }
    );
  }

  trackById(index, item){
    return item.id;
  }

  trackByIndex = (index: number): number => index;
}
