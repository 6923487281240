import { Injectable } from '@angular/core';
import { LoanSidebarComponentResolver, LoanSpecificComponentRecord } from '@backoffice-monorepo/commons';
import { PlPiLoanStagesComponent } from '../components/pl-pi-loan-stages/pl-pi-loan-stages.component';
import { PlPiLoanProlongationResignationsComponent } from '../components/pl-pi-loan-prolongation-resignations/pl-pi-loan-prolongation-resignations.component';
import { PlPiLoanVasComponent } from '../components/pl-pi-loan-vas/pl-pi-loan-vas.component';
import { TariffClientSidebarComponent } from '../modules/tariff/components/tariff-client-sidebar/tariff-client-sidebar.component';
import { TariffWriteOffComponent } from '../modules/tariff/components/tariff-write-off/tariff-write-off.component';
import { TariffRefundComponent } from '../modules/tariff/components/tariff-refund/tariff-refund.component';
import { RestructuringSidebarComponent } from '../modules/restructuring/components/restructuring-sidebar/restructuring-sidebar.component';

@Injectable({
  providedIn: 'root'
})
export class PlPiLoanSidebarComponentResolver implements LoanSidebarComponentResolver {

  resolve(): LoanSpecificComponentRecord[] {
    return [
      {index: 1, title: 'Debt collection stages', type: PlPiLoanStagesComponent},
      {index: 2, title: 'Prolongation resignations', type: PlPiLoanProlongationResignationsComponent},
      {index: 3, title: 'VAS', type: PlPiLoanVasComponent},
      {index: 4, title: 'Tariff plans', type: TariffClientSidebarComponent},
      {index: 5, title: 'Write-off transactions', type: TariffWriteOffComponent},
      {index: 6, title: 'Refund transactions', type: TariffRefundComponent},
      {index: 7, title: 'Payment schedule', type: RestructuringSidebarComponent},
    ]
  }
}
