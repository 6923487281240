<div class="container-fluid pt-2 col-sm-8">
    <div class="card" *ngIf="id == 0">
      <div class="card-header">
        {{tabTitle}}
        <span class="float-right">
          <button type="button" (click)="addPartner()"
                  appRequirePermission="admin.all"
                  class="btn btn-outline-danger"
                  [disabled]="partnerForm.invalid || partnerOtherForm.invalid">
            Save
          </button>
        </span>
      </div>
      <div class="card-body">
        <form [formGroup]="partnerForm">
          <div class="form-group row">
            <label for="name" class="col-sm-4 control-label">Partner name<sup class="text-danger">*</sup></label>
            <div class="col-sm-8">
              <input type="text" class="form-control form-control-sm" id="name" formControlName="name">
            </div>
          </div>
          <div class="form-group row">
            <label for="bankAccount" class="col-sm-4 control-label">Bank account<sup class="text-danger">*</sup></label>
            <div class="col-sm-8">
              <input type="text" class="form-control form-control-sm" id="bankAccount" formControlName="bankAccount">
            </div>
          </div>
          <div class="form-group row">
            <label for="enabledForPortfolioAutoAssignment" class="col-sm-4 control-label">Enabled for portfolio auto assignment</label>
            <div class="col-sm-8">
              <input type="checkbox" class="form-control form-control-sm" id="enabledForPortfolioAutoAssignment" formControlName="enabledForPortfolioAutoAssignment">
            </div>
          </div>
          <div class="form-group row">
            <label for="maxPortfolioAmount" class="col-sm-4 control-label">Max portfolio amount</label>
            <div class="col-sm-8">
              <input type="number" class="form-control form-control-sm" id="maxPortfolioAmount" formControlName="maxPortfolioAmount">
            </div>
          </div>
          <div class="form-group row">
            <label for="weight" class="col-sm-4 control-label">Weight<sup class="text-danger">*</sup></label>
            <div class="col-sm-8">
              <input type="number" class="form-control form-control-sm" id="weight" formControlName="weight" min="0" max="1" step="0.01">
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="card mt-1" [formGroup]="partnerOtherForm">
      <div class="card-header">
        Update partners weight
        <span class="float-right">
          <button type="button" *ngIf="id != 0" (click)="updatePartners()"
                  appRequirePermission="admin.all"
                  class="btn btn-outline-danger"
                  [disabled]="partnerOtherForm.invalid">
            Update
          </button>
        </span>
      </div>
        <div class="card-body" formArrayName="cardRows">
          <div class="card mt-2" *ngFor="let group of getFormControls.controls; trackBy: trackById; let i=index" [formGroupName]="i">
            <div class="card-body">
              <input type="hidden"  formControlName="id">
              <div class="container-fluid row">
                <div class="col-md-5">
                  <div class="form-group row">
                    <label for="name" class="col-sm-4 control-label">Partner name<sup class="text-danger">*</sup></label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control form-control-sm" formControlName="name">
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="bankAccount" class="col-sm-4 control-label">Bank account<sup class="text-danger">*</sup></label>
                    <div class="col-sm-8">
                      <input type="text" class="form-control form-control-sm"  formControlName="bankAccount">
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="enabledForPortfolioAutoAssignment" class="col-sm-4 control-label">Enabled for portfolio auto assignment</label>
                    <div class="col-sm-8">
                      <input type="checkbox" class="form-control form-control-sm" formControlName="enabledForPortfolioAutoAssignment">
                    </div>
                  </div>
                </div>
                <div class="col-md-5 ml-4">
                  <div class="form-group row">
                    <label for="maxPortfolioAmount" class="col-sm-4 control-label">Max portfolio amount</label>
                    <div class="col-sm-8">
                      <input type="number" class="form-control form-control-sm"  formControlName="maxPortfolioAmount">
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="weight" class="col-sm-4 control-label">Weight<sup class="text-danger">*</sup></label>
                    <div class="col-sm-8">
                      <input type="number" class="form-control form-control-sm" formControlName="weight" min="0" max="1" step="0.01">
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</div>



