import { Component, Input, OnInit } from '@angular/core';
import { LoanSidebarLibComponent } from '@backoffice-monorepo/commons';
import { FullLoan, SearchQueryBuilder, SearchResponse, TransactionEntry } from '@twino/backoffice-api';
import { take } from 'rxjs/operators';
import { PlPiTariffWriteOffRefundService } from '../../services/pl-pi-tariff-write-off-refund.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TariffTransactionChangeComponent } from '../tariff-transaction-change/tariff-transaction-change.component';
import { TariffTransactionChangeMode } from '../../api/tariff-write-off-refund';

@Component({
  selector: 'backoffice-monorepo-tariff-write-off',
  templateUrl: './tariff-write-off.component.html',
  styleUrls: ['./tariff-write-off.component.scss']
})
export class TariffWriteOffComponent implements LoanSidebarLibComponent, OnInit {
  @Input()
  loan: FullLoan;
  searchResults: SearchResponse<TransactionEntry> | never;
  newPage = 1;
  itemsPerPage = 20;

  constructor(
    private tariffWriteOffRefundService: PlPiTariffWriteOffRefundService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    const searchQuery = new SearchQueryBuilder()
      .withSortCriterion({
        propertyName: 'id',
        sortDirection: 'DESC'
      })
      .withPageSize(this.itemsPerPage)
      .withPage(this.newPage)
      .build()

    this.tariffWriteOffRefundService.listWriteOffTransactions(this.loan.id, searchQuery).pipe(
      take(1)
    ).subscribe(transactions => {
      this.searchResults = transactions;
    })
  }

  writeOff(transactionId: number) {
    const modalRef = this.modalService.open(TariffTransactionChangeComponent);
    modalRef.componentInstance.id = transactionId;
    modalRef.componentInstance.mode = TariffTransactionChangeMode.WRITE_OFF;
    modalRef.result.then(
      (result) => {
        if (result === true) {
          this.refresh();
        }
      }, () => {}
    );
  }

  trackById(index: number, item: any){
    return item.id;
  }

  pageChange($event?: number | null) {
    if ($event) this.newPage = $event;
    this.refresh();
  }
}
