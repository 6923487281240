import * as dayjs from 'dayjs'
import * as isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

export class DateUtils {

  constructor() {
  }

  static formatDate(date: dayjs.Dayjs): string {
    return date.format('YYYY-MM-DD');
  }

  static formatDateTime(date: dayjs.Dayjs): string {
    return date.format('YYYY-MM-DD HH:mm:ss');
  }

  static dateNow(): string {
    return this.formatDate(dayjs());
  }

  static dateTimeNow(): string {
    return this.formatDateTime(dayjs());
  }

  static dateTimeNowAsDate(): Date {
    return dayjs().toDate();
  }

  static dateNowPlusDays(days: number): string {
    return this.formatDate(dayjs().add(days, 'days'));
  }

  static dateNowPlusDays2Date(days: number): Date {
    return dayjs().add(days, 'days').toDate()
  }

  static datePlusHoursWithFormat(hours: number, date: Date): string {
    return this.formatDateTime(dayjs(date).add(hours, 'hours'));
  }

  static datePlusDays(days: number, date: Date): string {
    return this.formatDateTime(dayjs(date).add(days, 'days'));
  }

  static formatDateTimeFrom(value: string | Date): string {
    return this.formatDateTime(dayjs(value));
  }

  static formatDateFrom(value: string | Date): string {
    return this.formatDate(dayjs(value));
  }

  static dateArray2DateTime(array: number[]): string {
    const dateFromArray = new Date(
      array[0],
      array[1]-1,
      array[2],
      array[3],
      array[4],
      array[5],
    );
    return dayjs(dateFromArray).format('YYYY-MM-DD HH:mm:ss');
  }

  static dateArray2Date(array: number[]): string {
    const dateFromArray = new Date(
      array[0],
      array[1]-1,
      array[2],
      0,
      0,
      0,
    );
    return dayjs(dateFromArray).format('YYYY-MM-DD');
  }

  static isDateBetween(startDate: Date, endDate: Date): boolean {
    return dayjs().isBetween(dayjs(startDate), dayjs(endDate), 'day', '[]' )
  }

  static currentDateWithFixedDay(day: number, time = false): string {
    const date = dayjs().date(day);
    return this.formatDateOrDateTime(date, time);
  }

  static previousMonthWithFixedDay(day: number, time = false): string {
    const date = dayjs().subtract(1, 'month').date(day);
    return this.formatDateOrDateTime(date, time);
  }

  static getDate30DaysBeforeToday(time = false): string {
    const date = dayjs().subtract(30, 'day');
    return this.formatDateOrDateTime(date, time);
  }

  static formatDateOrDateTime(date: dayjs.Dayjs, time: boolean): string {
    return time ? this.formatDateTime(date.hour(0).minute(0).second(0)) : this.formatDate(date);
  }

  static dateFromDay(day: number): Date {
    return dayjs().add(1, 'month').date(day).toDate();
  }
}
