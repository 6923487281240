import { Component, Input, OnInit } from '@angular/core';
import { AlertsService, BasicComponent, TwinoDateAdapterService } from '@backoffice-monorepo/commons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { PlPiTariffWriteOffRefundService } from '../../services/pl-pi-tariff-write-off-refund.service';
import { RefundTariffFeeRequest, TariffTransactionChangeMode, WriteOffTariffFeeRequest } from '../../api/tariff-write-off-refund';

@Component({
  selector: 'backoffice-monorepo-tariff-transaction-change',
  templateUrl: './tariff-transaction-change.component.html',
  styleUrls: ['./tariff-transaction-change.component.css'],
  providers: [
    {provide: NgbDateAdapter, useClass: TwinoDateAdapterService}
  ]
})
export class TariffTransactionChangeComponent extends BasicComponent implements OnInit {

  @Input() id: number;
  @Input() mode: TariffTransactionChangeMode;
  form: FormGroup;
  title: string | undefined;

  constructor(
    public activeModal: NgbActiveModal,
    private alertService: AlertsService,
    private formBuilder: FormBuilder,
    private tariffWriteOffRefundService: PlPiTariffWriteOffRefundService
  ) {
    super();
  }

  ngOnInit(): void {
    this.title = (this.mode === TariffTransactionChangeMode.WRITE_OFF) ? 'Write-off Transaction' : 'Refund Transaction';
    this.form = this.formBuilder.group({
      bookingDate: ['', Validators.required],
    });
  }

  submitForm() {
    if(this.mode === TariffTransactionChangeMode.REFUND) {
      this.refund();
    }
    if(this.mode === TariffTransactionChangeMode.WRITE_OFF) {
      this.writeOff();
    }
  }

  writeOff() {
    const request: WriteOffTariffFeeRequest = {
      transactionId: this.id,
      bookingDate: this.form.get('bookingDate').value
    };
    this.tariffWriteOffRefundService.writeOffTariffFee(request)
     .pipe(
        takeUntil(this.$destroy)
      ).subscribe(() => {
      this.alertService.notifySuccess(`Write-off request sent`);
      this.activeModal.close(true);
    })
  }

  refund() {
    const request: RefundTariffFeeRequest = {
      transactionId: this.id,
      bookingDate: this.form.get('bookingDate').value
    };
    this.tariffWriteOffRefundService.refundTariffFee(request)
     .pipe(
        takeUntil(this.$destroy)
      ).subscribe(() => {
      this.alertService.notifySuccess(`Refund request sent`);
      this.activeModal.close(true);
    })
  }
}
