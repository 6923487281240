import { Component, OnInit } from '@angular/core';
import { AlertsService, NamedComponent, SalePartnerService, ViewHistoryService } from '@backoffice-monorepo/commons';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CreateLoanSalePartnerRequest, LoanSalePartnerResponse, SearchQueryBuilder, UpdateLoanSalePartnersRequest } from '@twino/backoffice-api';
import { take, takeUntil } from 'rxjs/operators';
import { SalePartnersRefreshService } from '../../services/sale-partners-refresh.service';

@Component({
  selector: 'backoffice-monorepo-sale-partner-update',
  templateUrl: './sale-partner-update.component.html',
  styleUrls: ['./sale-partner-update.component.css']
})
export class SalePartnerUpdateComponent extends NamedComponent implements OnInit {

  id: number | null;
  partnerForm: FormGroup;
  partnerOtherForm: FormGroup;
  tabTitle: string | undefined = '';
  partners: LoanSalePartnerResponse[];


  constructor(
    activatedRoute: ActivatedRoute,
    private alertService: AlertsService,
    private formBuilder: FormBuilder,
    private viewHistoryService: ViewHistoryService,
    private salePartnerService: SalePartnerService,
    private salePartnersRefreshService: SalePartnersRefreshService
  ) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.params['id']; console.log(this.id);
    this.tabTitle = (this.id > 0) ? `Update sale partners` : `Add sale partner`;
    this.partnerOtherForm = this.formBuilder.group({
      cardRows: this.formBuilder.array([])
    });
    this.partnerForm = this.formBuilder.group({
      name: ['', Validators.required],
      bankAccount: ['', Validators.required],
      enabledForPortfolioAutoAssignment: [false],
      maxPortfolioAmount: [''],
      weight: ['',  Validators.required]
    });
    this.getPartners();
  }

  getName(): string {
    return `${this.tabTitle}`;
  }


  getPartners() {
    const searchQuery = new SearchQueryBuilder()
      .build()
    return this.salePartnerService.find(searchQuery).pipe(
      take(1)
    ).subscribe((response) => {
      this.partners = response.results;
      this.setRows(this.partners);
    })
  }

  addPartner() {
      const request: CreateLoanSalePartnerRequest = {
        newLoanSalePartnerRequest: this.partnerForm.value,
        updatedExistingLoanSalePartners: this.partnerOtherForm.value.cardRows
      }
      this.salePartnerService.createPartner(request).pipe(
        takeUntil(this.$destroy)
      ).subscribe(() => {
        this.alertService.notifySuccess(`Partner ${this.partnerForm.controls.name.value} added`);
        this.salePartnersRefreshService.reloadSalePartnersModel();
        this.viewHistoryService.closeTab();
      })
  }

  updatePartners(){
    const request: UpdateLoanSalePartnersRequest = {
      loanSalePartners: this.partnerOtherForm.value.cardRows
    }
    this.salePartnerService.updatePartner(request).pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
      this.alertService.notifySuccess(`Partners list updated`);
      this.salePartnersRefreshService.reloadSalePartnersModel();
    })
  }

  createForm(): FormGroup {
    return this.formBuilder.group({
      id: ['', Validators.required],
      name: ['', Validators.required],
      bankAccount: ['', Validators.required],
      enabledForPortfolioAutoAssignment: [false],
      maxPortfolioAmount: [''],
      weight: ['',  Validators.required]
    });
  }

  setFormGroupValues(values: LoanSalePartnerResponse) {
    const formGroupRule = this.createForm();
    formGroupRule.setValue({
      id: values.id,
      name: values.name,
      bankAccount: values.bankAccount,
      enabledForPortfolioAutoAssignment: values.enabledForPortfolioAutoAssignment,
      maxPortfolioAmount: values.maxPortfolioAmount as number,
      weight: values.weight
    });
    return formGroupRule;
  }

  get getFormControls() {
    return this.partnerOtherForm.get('cardRows') as FormArray;
  }

  setRows(partners: LoanSalePartnerResponse[]) {
    const formControl = this.partnerOtherForm.get('cardRows') as FormArray;
    partners.forEach((values) => {
        formControl.push(this.setFormGroupValues(values));
      });

  }

  trackById(index: number, item: any){
    return item.id;
  }

}
