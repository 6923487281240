import { Component, OnChanges } from '@angular/core';
import { CountryViewComponent, NUMBER, TransformInstructions, TransformInstructionsBuilder, Utils } from '@backoffice-monorepo/commons';
import { FullLoan } from '@twino/backoffice-api';
import { PlPiLoanService } from '../../services/pl-pi-loan.service';
import { take } from 'rxjs/operators';
import { PlPiRestructuringService } from '../../modules/restructuring/services/pl-pi-restructuring.service';
import { RestructuringAvailabilityResponse } from '../../modules/restructuring/api/restructuring';

@Component({
  selector: 'backoffice-monorepo-pl-pi-loan-view-fields',
  templateUrl: './pl-pi-loan-view-fields.component.html',
  styleUrls: ['./pl-pi-loan-view-fields.component.css']
})
export class PlPiLoanViewFieldsComponent implements CountryViewComponent, OnChanges {

  data: FullLoan;
  diPocketBalance: number | string;
  restructuringAvailability: RestructuringAvailabilityResponse;
  balance: string | null

  loanTransformations: TransformInstructions = TransformInstructionsBuilder.build(
    [
      ['daysToRepaymentDate', ['Days to repayment date', NUMBER]],
      ['', ['Balance', () => `${this.balance}`.asString()]]
    ]
  );

  constructor(
    private loanService: PlPiLoanService,
    private restructuringService: PlPiRestructuringService
  ) {}

  ngOnChanges() {
    const balance = this.data?.currentAgreement?.amount - this.data.principalBalance;
    this.balance = Utils.roundAmount(balance);
    this.loanService.getCardBalance(this.data.id).pipe(take(1)).subscribe(response => {
      this.diPocketBalance = response;
    }, error => {
      this.diPocketBalance = "Card not active";
    });
    this.getRestructuringAvailability();
  }

  getRestructuringAvailability() {
    this.restructuringService.getRestructuringAvailability(this.data.id).pipe(take(1)).subscribe(response => {
      this.restructuringAvailability = response;
    });
  }
}
