import { Transformation } from './transform.types';
// noinspection ES6PreferShortImport
import { Utils } from '../../services/utils';
import { CommissionRateParameters, DiscountShortInfo, DiscountType } from '@twino/backoffice-api';
import { DateUtils } from '../date-utils';

export const ID: Transformation = (value) => value.toString().asString()
export const STRING: Transformation = (value) => value.toString().asString()
export const BOOLEAN: Transformation = (value) => (value === true) ? "true".asString() : "false".asString()
export const DATE: Transformation = (value: Date | null) => value.toString().asString()
export const DATE_TIME: Transformation = (value: Date | null) => DateUtils.formatDateTimeFrom(value).asString()
export const NUMBER: Transformation = (value) => Utils.parseInt(value).asString()
export const AMOUNT: Transformation = (value) => Utils.parseAmount(value).asString()
export const CLIENT: Transformation = (value) => `<a href="#/clients/${value}">${value}</a>`.asHtml()
export const LOAN: Transformation = (value) => `<a href="#/loans/${value}">${value}</a>`.asHtml()
export const LIST: Transformation = (value) => Transforms.transformObjectData(value).asHtml()
export const DISCOUNT_PERCENT: Transformation = (value: Map<DiscountType, DiscountShortInfo>) => Transforms.transformDiscountPercent(value).asString()
export const PERCENT: Transformation = (value) => Utils.toPercentString(value).asString()
export const RATIO_AS_PERCENT: Transformation = (value) => Utils.convertRatioToPercent(value).asString()
export const COMMISSIONS: Transformation = (value) => Transforms.transformCommissions(value).asHtml()
export const RATE_PER_MONTH_AS_PERCENT: Transformation = (value) => Transforms.ratePerMonthInPercent(value).asString()

export class Transforms {

  static transformObjectData(object: unknown): string {
    let tableRows = '';
    Object.entries(object).forEach(([key, value]) => {
      tableRows += `<tr><td>${key}</td><td>${value}</td></tr>`;
    });
    return '<table class="table table-sm">' + tableRows + '</table>';
  }


  static transformDiscountPercent(appliedDiscounts: Map<DiscountType, DiscountShortInfo>): string {
    return Object.values(appliedDiscounts)
      .map(discountShortInfo => discountShortInfo.discountRatio)
      .reduce((previousValue, currentValue) => Math.max(previousValue, currentValue), 0)
      .toString()
  }

  static transformCommissions(object: unknown): string {
    let tableRows = '';
    Object.entries(object).forEach(([key,value]) => {
      tableRows += `<tr><td>${value['accountType']}</td><td>Rate per year: ${value['ratePerYear']}</td></tr>`;
    });
    return '<table class="table table-sm">' + tableRows + '</table>';
  }

  static ratePerMonthInPercent(object: CommissionRateParameters[]): string {
    const commission =  object.filter((item: CommissionRateParameters) => item.accountType === "LOAN_COMMISSION");
    return Utils.toPercentString(commission[0].ratePerMonthInPercent);
  }
}
