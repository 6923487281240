import { Component, Input, OnInit } from '@angular/core';
import { FullLoan, LoanStatusDetail } from '@twino/backoffice-api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  BasicComponent, ClientService,
  ConfirmDialogueComponent,
  CountryViewComponent,
  draggableModalOptions
} from '@backoffice-monorepo/commons';
import { PlPiLoanService } from '../../services/pl-pi-loan.service';
import { PlPiSetRenouncementModalComponent } from '../pl-pi-set-renouncement-modal/pl-pi-set-renouncement-modal.component';
import { LoanRenouncementRequest } from '../../api/loan-renouncement';
import { PlPiLoanCloseComponent } from '../pl-pi-loan-close/pl-pi-loan-close.component';
import { PlPiTransferOutgoingAddComponent } from '../pl-pi-transfer-outgoing-add/pl-pi-transfer-outgoing-add.component';
import { Subject } from 'rxjs';
import { PlPiIncreaseLimitComponent } from '../pl-pi-increase-limit/pl-pi-increase-limit.component';
import { PlPiLoanMtpAbatementComponent } from '../pl-pi-loan-mtp-abatement/pl-pi-loan-mtp-abatement.component';
import { RestructuringStateResponseBackoffice, RestructuringStateType } from '../../modules/restructuring/api/restructuring';
import { RestructuringAddressComponent } from '../../modules/restructuring/components/restructuring-address/restructuring-address.component';
import { RestructuringCalculatorComponent } from '../../modules/restructuring/components/restructuring-calculator/restructuring-calculator.component';
import { LoansRefreshService } from '../../../../../../libs/core/src/lib/modules/loans/services/loans-refresh-services';
import { take } from 'rxjs/operators';
import { PlPiRestructuringService } from '../../modules/restructuring/services/pl-pi-restructuring.service';

@Component({
  selector: 'backoffice-monorepo-pl-pi-loan-button-view',
  templateUrl: './pl-pi-loan-button-view.component.html',
  styleUrls: ['./pl-pi-loan-button-view.component.scss']
})
export class PlPiLoanButtonViewComponent extends BasicComponent implements CountryViewComponent, OnInit {

  @Input()
  data: FullLoan;
  showButtons = false;
  showResumeButton = false;
  $destroy = new Subject<boolean>();
  restructuringState: RestructuringStateResponseBackoffice;
  restructuringStateType = RestructuringStateType;

  constructor(
    private modalService: NgbModal,
    private loanService: PlPiLoanService,
    private loanRefreshService: LoansRefreshService,
    private clientService: ClientService,
    private restructuringService: PlPiRestructuringService,
  ) {
    super();
  }

  ngOnInit() {
    this.showButtons = this.data.open && !this.isClosedLoan();
    this.showResumeButton = this.data.paused && !this.isClosedLoan();
    this.getRestructuringState();
  }

  closeLoan() {
    const modalRef = this.modalService.open(PlPiLoanCloseComponent);
    modalRef.componentInstance.loanId = this.data.id;
  }

  pauseLoan() {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = "Pause loan";
    modalRef.componentInstance.content = "Loan will be paused";
    modalRef.result.then(
      (result) => {
        if (result === true) this.loanService.pauseLoan(this.data.id).subscribe();
      }, () => {}
    );
  }

  resumeLoan() {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = "Resume loan";
    modalRef.componentInstance.content = "Loan will be resume";
    modalRef.result.then(
      (result) => {
        if (result === true) this.loanService.resumeLoan(this.data.id).subscribe();
      }, () => {}
    );
  }

  setRenouncement() {
    const modalRef = this.modalService.open(PlPiSetRenouncementModalComponent, draggableModalOptions);
    modalRef.result.then(
      (result: LoanRenouncementRequest) => {
        if (result) this.loanService.renounceLoan(this.data.id, result).subscribe();
      }, () => {}
    );
  }

  sendTransferToClient() {
    const modalRef = this.modalService.open(PlPiTransferOutgoingAddComponent);
    modalRef.componentInstance.loanId = this.data.id;
    modalRef.componentInstance.clientId = this.data.clientId;
  }

  increaseLimit() {
    const modalRef = this.modalService.open(PlPiIncreaseLimitComponent);
    modalRef.componentInstance.loan = this.data;
  }

  mtpAbatement() {
    const modalRef = this.modalService.open(PlPiLoanMtpAbatementComponent);
    modalRef.componentInstance.loan = this.data;
  }

  isClosedLoan() {
    return this.data.statusDetail === LoanStatusDetail.PENDING_TERMINATION_BY_CLIENT
      || this.data.statusDetail === LoanStatusDetail.PENDING_TERMINATION_BY_COMPANY
    || this.data.statusDetail === LoanStatusDetail.AML_TERMINATION_BY_COMPANY;
  }

  paymentSchedule() {
    this.clientService.getClientAddresses(this.data.clientId).pipe(take(1)).subscribe(response => {
      const modalRef = this.modalService.open(RestructuringAddressComponent, draggableModalOptions);
      modalRef.componentInstance.loan = this.data;
      modalRef.componentInstance.addresses = response;
      modalRef.result.then(
        (result) => {
          if(result === true) {
            this.openCalculator();
          }
        }, () => {}
      );
    });
  }

  openCalculator() {
    const modalRef = this.modalService.open(RestructuringCalculatorComponent, draggableModalOptions);
    modalRef.componentInstance.loan = this.data;
    modalRef.componentInstance.state = this.restructuringState;
    modalRef.result.then(
      (result) => {
        if(result === true) {
          this.paymentScheduleTab();
          this.getRestructuringState();
        }
      }, () => {}
    );
  }

  paymentScheduleTab() {
    this.loanRefreshService.switchLoanTab();
  }

  getRestructuringState() {
    this.restructuringService.getCurrentRestructuringState(this.data.id).pipe(take(1)).subscribe(state => {
      this.restructuringState = state;
    })
  }
}
