import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoansRefreshService implements OnDestroy{

  public reloadLoanRepaymentProposalsModel$ = new Subject<void>();
  public reloadLoanModel$ = new Subject<void>();
  public switchLoanTab$ = new Subject<void>();


  reloadLoanRepaymentProposalsModel() {
    this.reloadLoanRepaymentProposalsModel$.next();
  }

  reloadLoanModel() {
    this.reloadLoanModel$.next();
  }

  switchLoanTab() {
    this.switchLoanTab$.next();
  }

  ngOnDestroy(): void {
    this.reloadLoanRepaymentProposalsModel$.unsubscribe();
    this.reloadLoanModel$.unsubscribe();
    this.switchLoanTab$.unsubscribe();
  }
}
