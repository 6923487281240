import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { AlertsService, BasicComponent, ClientDocumentService, TwinoDateAdapterService } from '@backoffice-monorepo/commons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IdentityDocument, IdentityDocumentRequest } from '@twino/backoffice-api';
import { ClientsRefreshService } from '../../services/clients-refresh.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'client-identity-document-edit',
  templateUrl: './client-identity-document-edit.component.html',
  styleUrls: ['./client-identity-document-edit.component.scss'],
  providers: [
    {provide: NgbDateAdapter, useClass: TwinoDateAdapterService}
  ]
})
export class ClientIdentityDocumentEditComponent extends BasicComponent implements OnInit {

  @Input() identityDocument: IdentityDocument;

  clientIdentityDocumentEditForm: FormGroup;
  clientIdentityDocumentRequest: IdentityDocumentRequest;

  constructor(
    public activeModal: NgbActiveModal,
    private alertService: AlertsService,
    private clientDocumentService: ClientDocumentService,
    private formBuilder: FormBuilder,
    private clientsRefreshService: ClientsRefreshService
  ) {
    super();
  }

  ngOnInit(): void {
    this.clientIdentityDocumentEditForm = this.formBuilder.group({
      number: ['', Validators.required],
      active: false,
      issueDate: ['', Validators.required],
      expiryDate: null,
      isWithoutExpiryDate: false,
      authority: null,
      authorityCode: null,
      authorityCountry: null
    });
    this.clientIdentityDocumentRequest = {
      number: this.identityDocument.number,
      active: this.identityDocument.active,
      issueDate: this.identityDocument.issueDate,
      expiryDate: this.identityDocument.expiryDate,
      isWithoutExpiryDate: this.identityDocument.isWithoutExpiryDate,
      authority: this.identityDocument.authority,
      authorityCode: this.identityDocument.authorityCode,
      authorityCountry: this.identityDocument.authorityCountry
    }
    this.clientIdentityDocumentEditForm.patchValue(this.clientIdentityDocumentRequest);
  }

  submitForm() {
    this.clientDocumentService.updateIdentityDocument(
      this.identityDocument.clientId,
      this.clientIdentityDocumentEditForm.value,
      this.identityDocument.id
    ).pipe(
        takeUntil(this.$destroy)
      ).subscribe(() => {
      this.clientsRefreshService.reloadClientModel();
      this.alertService.notifySuccess(`Identity document updated for client ${this.identityDocument.clientId}`);
      this.activeModal.close(true);
    })
  }
}

