export interface DebtStageResponse {
  id: number
  loanId: number
  stageType: DebtStageType
  status: DebtStageStatus
  startDate: Date
  endDate?: Date
  description?: string
}

export interface SetDebtStageRequest {
  stageType: DebtStageType
  description?: string
}

export enum DebtStageType {
  NORMAL = 'NORMAL',
  SURVEILLANCE = 'SURVEILLANCE',
  POLICE = 'POLICE',
  MONITORING = 'MONITORING',
  EXTERNAL_COLLECTION = 'EXTERNAL_COLLECTION',
  UNDERPAYMENT = 'UNDERPAYMENT',
  TERMINATION_PENDING = 'TERMINATION_PENDING',
  TERMINATION = 'TERMINATION',
  PRE_SOLD = 'PRE_SOLD',
  SOLD = 'SOLD',
  PRE_WITHDRAW = 'PRE_WITHDRAW',
  WITHDRAW = 'WITHDRAW',
  FRAUD = 'FRAUD'
}

export enum DebtStageStatus {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED'
}

export interface MarkLoanAsFraudRequest {
  onDate: string
  fraudType: FraudType
}

export enum FraudType {
  REMISSION = 'REMISSION',
  RESOLUTION = 'RESOLUTION',
  DECEASE = 'DECEASE',
  NOT_FRAUD = 'NOT_FRAUD'
}

export interface ChangeManualDcStatusRequest {
  onTime: string
}

export enum LoanDebtStepManualStatus {
  DECEASE = 'DECEASE',
  POLICE = 'POLICE',
  INSOLVENCY = 'INSOLVENCY',
  REMOVE_FOR_SALE = 'REMOVE_FOR_SALE',
  PRE_BUY_BACK = 'PRE_BUY_BACK',
  BUY_BACK = 'BUY_BACK',
}

export interface BuyBackRequest {
  reason: string
  onDate: Date
}

export enum BuyBackReasons {
  RECEIVABLE_REMOVED_FOR_SALE = 'RECEIVABLE_REMOVED_FOR_SALE',
  DEATH_BEFORE_CONTRACT_CONCLUSION = 'DEATH_BEFORE_CONTRACT_CONCLUSION',
  EXTORTION = 'EXTORTION',
  NO_DOCUMENTATION = 'NO_DOCUMENTATION',
  NO_NOTICE = 'NO_NOTICE',
  INVALIDITY_OF_CONTRACT = 'INVALIDITY_OF_CONTRACT',
  RECEIVABLES_BAN_ON_SALE = 'RECEIVABLES_BAN_ON_SALE',
  LIMITATION_OF_CLAIMS = 'LIMITATION_OF_CLAIMS',
  CONSUMER_BANKRUPTCY_AFTER_CONTRACT_CONCLUSION = 'CONSUMER_BANKRUPTCY_AFTER_CONTRACT_CONCLUSION',
  OTHER = 'OTHER',
  WITHDRAWAL = 'WITHDRAWAL',
}

export interface RemoveFromSaleRequest {
  loanIds: number[]
  onDate: string
}
