import { SearchModel } from './search/search.model';

export class Task implements SearchModel {
  id: number;
  clientId: number;
  applicationId?: number;
  loanId?: number;
  brand?: string;
  attempts: number;
  nextProcessTime: Date;
  assignedTime?: Date;
  topic: string;
  inputData?: string;
  relatedEntities: Map<string, any>;
  assignedUser?: string;
  taskStatus: string;
  possibleResolutions: string[];
  resolution?: string;
  taskGroup?: TaskGroup;
  closeTimestamp?: Date;
  comment: string;
  created: Date;
  taskGroupName: string;
  receiveChannel: string;
}

export class PostponeTaskRequest {
  postponeDate: string;
}

export class CloseTaskRequest {
  resolution: string;
  inputData = {};
}

export class TaskDefinition {
  topic: string;
  resolutions: Set<string>;
  assignedPermissions: Set<string>;
  relatedEntitiesNames: Set<string>;
  initialDelay: string
}

export class TaskGroup {
  id: number;
  groupName: string;
}

export enum TaskStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  CANCELLED = 'CANCELLED'
}

export enum TaskResolution {
  PROMISE_TO_PAY = 'promise to pay'
}

export interface TaskTopicWithCountResponse {
  topic: string
  count: number
  isMailBoxTopic: boolean
  toProcessCount: number
}

export interface MailboxAddressesResponse {
  mailboxAddresses: MailboxAddressAndTasksCountResponse[]
}

export interface MailboxAddressAndTasksCountResponse {
  mailboxAddress: string
  tasksCount: number
}
