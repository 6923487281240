  <div class="modal-header">
    <h4 class="modal-title">Activate card</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div [formGroup]="cardDigitsForm"  *ngIf="stage === 1">
      <div class="form-group row">
        <label for="lastFourDigits" class="col-sm-6 control-label">Last 4 digits of card number<sup class="text-danger">*</sup></label>
        <div class="col-sm-6">
          <input type="text" class="form-control" formControlName="lastFourDigits" id="lastFourDigits">
        </div>
      </div>
    </div>

    <div [formGroup]="otpConfirmForm"  *ngIf="stage === 2">
      <div class="form-group row">
        <label for="confirmationCode" class="col-sm-5 control-label">Confirmation code<sup class="text-danger">*</sup></label>
        <div class="col-sm-7">
          <input type="text" class="form-control" formControlName="confirmationCode" id="confirmationCode">
        </div>
      </div>
    </div>

    <div *ngIf="($resendCountdown | async) === 0" class="row">
      <button class="btn btn-link" (click)="resendOtp()" *ngIf="stage === 2">Resend OTP</button>
    </div>

    <div *ngIf="($resendCountdown | async) > 0" class="row">
      <button class="btn btn-link" disabled *ngIf="stage === 2">Resend code in {{ $resendCountdown | async }} sec</button>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('Close click')">Close</button>
    <button type="button" class="btn btn-outline-danger" *ngIf="stage === 1" [disabled]="!cardDigitsForm.valid" (click)="confirm()">Confirm</button>
    <button type="button" class="btn btn-outline-danger" *ngIf="stage === 2" [disabled]="!otpConfirmForm.valid" (click)="submit()">Confirm</button>
  </div>



