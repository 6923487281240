<div class="container-fluid row pt-1 pb-2">
  <p class="mt-2 ml-3">
    <b>Identity Documents</b>
  </p>
  <!-- region buttons -->
  <div class="col-md-12 mb-1">
        <span class="mr-1 float-right">
                <button class="btn btn-success btn-sm"  (click)="refresh()">
                  Refresh
                </button>
        </span>
        <span class="mr-1 float-right">
            <button appRequirePermission="client.all,client.edit,admin.all"
                    class="btn btn-primary btn-sm"
                    (click)="createIdentityDocument(client.savedId)">
              Create document
            </button>
        </span>
  </div>
  <!-- endregion -->
  <div class="col-md-12">
    <server-table>
      <table list-table class="table table-striped table-hover table-sm">
        <thead class="text-light bg-primary">
        <tr>
          <th></th>
          <th>Number</th>
          <th>Type</th>
          <th>Active</th>
          <th>Issue date</th>
          <th>Expiry date</th>
          <th>Without expiry date</th>
          <th>Authority</th>
          <th>Authority country</th>
          <th>Authority code</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of identityDocuments">
          <td></td>
          <td>{{ item.number }}</td>
          <td>{{ item.type }}</td>
          <td>{{ item.active }}</td>
          <td>{{ item.issueDate | date: 'dd.MM.yyyy' }}</td>
          <td>{{ item.expiryDate | date: 'dd.MM.yyyy' }}</td>
          <td>{{ item.isWithoutExpiryDate }}</td>
          <td>{{ item.authority }}</td>
          <td>{{ item.authorityCountry }}</td>
          <td>{{ item.authorityCode }}</td>
          <td>
            <span class="mr-2">
              <button appRequirePermission="client.all,client.edit,admin.all"
                      class="btn btn-success btn-sm"
                      (click)="editIdentityDocument(item)">
                <bi name="pencil-square"></bi>
                Edit
              </button>
            </span>
          </td>
        </tr>
        </tbody>
      </table>
    </server-table>
  </div>
</div>
