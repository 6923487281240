export class Term {
  billingCycle: TermItem[]
  loanLength: TermItem
}

export class TermItem {
  value: number
  unit: TermUnit
}

export type TermUnit =
  'MONTHS'
  | 'DAYS'
  | 'WEEKS'

