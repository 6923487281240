import { Component, Host, OnDestroy, OnInit } from '@angular/core';
import { draggableModalOptions, FilteringService, ListComponent, PaymentService } from '@backoffice-monorepo/commons';
import { FilterOperation, Payment, PaymentStatus, SearchQueryAware, SearchQueryBuilder } from '@twino/backoffice-api';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentFromSurplusAddComponent } from '../payment-from-surplus-add/payment-from-surplus-add.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { PaymentsRefreshService } from '../../services/payments-refresh.service';

@Component({
  selector: 'payments-list',
  templateUrl: './payments-list.component.html',
  styleUrls: ['./payments-list.component.scss'],
  providers: [
    {
      provide: FilteringService,
      useFactory: (listService: SearchQueryAware<Payment>) => new FilteringService(listService),
      deps: [PaymentService]
    }
  ]
})
export class PaymentsListComponent extends ListComponent<Payment> implements OnInit, OnDestroy {

  private reloadPaymentsListModel: Subscription;
  exportModuleName = "payments";

  constructor(
    @Host() filteringService: FilteringService<Payment>,
    protected router: Router,
    activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private paymentsRefreshService: PaymentsRefreshService
  ) {
    super(activatedRoute, filteringService);
  }

  ngOnInit(): void {
    const searchQueryBuilder = new SearchQueryBuilder()
      .addFilterData({
        enumClass: "eu.twino.loans.core.payment.api.PaymentStatus",
        propertyName: "status",
        operation: FilterOperation.NOT_EQUALS,
        type: "enum",
        values: [PaymentStatus.VOIDED]
      })
    this.searchQuery = searchQueryBuilder.build();
    super.ngOnInit();
    this.reloadPaymentsListModel = this.paymentsRefreshService.reloadPaymentsListModel$.subscribe(() => {
      super.ngOnInit();
    })
  }

  ngOnDestroy(): void {
    this.reloadPaymentsListModel.unsubscribe();
  }

  getName(): string {
    return 'Payments';
  }

  navigateTo(id: number) {
    this.router.navigate([`/payments/${id}`]);
  }

  createPaymentFromSurplus() {
    this.modalService.open(PaymentFromSurplusAddComponent, draggableModalOptions);
  }

}
