import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DiscountShortInfo, DiscountType} from '@twino/backoffice-api';

@Component({
  selector: 'loan-applied-discounts',
  templateUrl: './loan-applied-discounts.component.html',
  styleUrls: ['./loan-applied-discounts.component.scss']
})
export class LoanAppliedDiscountsComponent {
  @Input() appliedDiscounts: Map<DiscountType, DiscountShortInfo>;
  @Input() loanId: number;

  constructor(
    public activeModal: NgbActiveModal,
  ) { }


  trackById(index, item){
    return item.key;
  }
}
