import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService, ErrorsService } from '@backoffice-monorepo/commons';
import { Observable } from 'rxjs';
import { BaseAddress, WaskoResponse } from '../api/address';

@Injectable({
  providedIn: 'root'
})
export class PlPiWaskoService extends BaseService {
  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  getAvailableAddressList(postalCode: string): Observable<WaskoResponse> {
    return this.get(`/api/pl/wasko/${postalCode}`);
  }

  saveAddressesInLocalStorage(postal: string, addressList: BaseAddress[]) {
    localStorage.setItem(`postal${postal}`, JSON.stringify(addressList));
  }

  getAddressesFromLocalStorage(postal: string): BaseAddress[] {
    return JSON.parse(localStorage.getItem(`postal${postal}`));
  }

  getProvince(postal: string, city: string, street: string | null): string {
    if(street === null) {
      return this.getAddressesFromLocalStorage(postal).filter(address => address.city === city)[0]?.province;
    } else {
      return this.getAddressesFromLocalStorage(postal).filter(address => address.city === city && address.street === street)[0]?.province;
    }
  }
}
