import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SalePartnersListComponent } from './components/sale-partners-list/sale-partners-list.component';
import { SalePartnersRoutingModule } from './sale-partners-routing.module';
import { CommonsModule } from '@backoffice-monorepo/commons';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SalePartnerUpdateComponent } from './components/sale-partner-update/sale-partner-update.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgJsonEditorModule } from 'ang-jsoneditor';



@NgModule({
  declarations: [SalePartnersListComponent, SalePartnerUpdateComponent],
	imports: [
		CommonModule,
		SalePartnersRoutingModule,
		CommonsModule,
		NgbNavModule,
		TranslateModule,
		ReactiveFormsModule,
		DragDropModule,
		NgJsonEditorModule,
	]
})
export class SalePartnersModule { }
