<div id="admin">
  <nav class="navbar navbar-expand-md sticky-top navbar-dark bg-primary">
    <a class="navbar-brand" routerLink="/home">{{projectName}}</a>
    <button class="navbar-toggler hidden-sm-up" type="button" (click)="isNavbarCollapsed = !isNavbarCollapsed" data-target="#navbarsDefault"
            aria-controls="navbarsDefault" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item dropdown" ngbDropdown>
          <a class="nav-link dropdown-toggle" id="workspace" ngbDropdownToggle>Workspace</a>
          <div class="dropdown-menu" aria-labelledby="workspace" ngbDropdownMenu>
            <a class="dropdown-item" appRequirePermission="admin.all,client.read,client.all" routerLink="/clients">Clients</a>
            <a class="dropdown-item" appRequirePermission="admin.all,loan-application.read,loan.admin" routerLink="/applications">
              Loan applications
            </a>
            <a class="dropdown-item" appRequirePermission="admin.all,loan.read,loan.admin" routerLink="/loans">Loans</a>
            <a class="dropdown-item" appRequirePermission="admin.all,client.register,client.all" (click)="setBrand('NETCREDIT')" style="cursor: pointer">
              Phone application NETCREDIT
            </a>
            <a class="dropdown-item" appRequirePermission="admin.all,client.register,client.all" (click)="setBrand('HALVO')" style="cursor: pointer">
              Phone application HALVO
            </a>
            <a class="dropdown-item" appRequirePermission="admin.all,debt-step-configuration.read" routerLink="/debt-collection-stages">
              Debt collection stages
            </a>
            <a class="dropdown-item" appRequirePermission="admin.all,payment.read" routerLink="/payments">Payments</a>
            <a class="dropdown-item" appRequirePermission="admin.all,payment-distribution.read" routerLink="/payment-distributions">
              Payment distributions
            </a>
            <a class="dropdown-item" appRequirePermission="admin.all,transfer.read" routerLink="/transfers">Transfers</a>
            <a class="dropdown-item" appRequirePermission="admin.all,communications.read" routerLink="/communications">Communications</a>
            <a class="dropdown-item" appRequirePermission="admin.all,task.read" routerLink="/tasks">Tasks list</a>
            <a class="dropdown-item" appRequirePermission="admin.all,task-group.read" routerLink="/tasks/groups">Task groups</a>
            <a class="dropdown-item" appRequirePermission="admin.all,task.read" routerLink="/tasks/dashboard">Tasks dashboard</a>
            <a class="dropdown-item" appRequirePermission="admin.all,activity-overview.read" routerLink="/activity-overview">Activity overview</a>
            <a class="dropdown-item" appRequirePermission="personal-id-blacklist.edit" routerLink="/creditg-personal-id-blacklist-entries">
              Personal ID blacklist
            </a>
            <a class="dropdown-item" appRequirePermission="payments.import-export" routerLink="/payments-import-export">Payments import/export</a>
            <a class="dropdown-item" appRequirePermission="payment.manual-export" routerLink="/payment-monitor">Payments dashboard</a>
            <a class="dropdown-item" appRequirePermission="admin.all,personal-id-blacklist.edit,personal-id-blacklist.read"
               routerLink="/creditg-personal-id-blacklist-entries">Personal ID blacklist</a>
            <a class="dropdown-item" appRequirePermission="admin.all,discount-campaign.read,discount-campaign.all"
               routerLink="/discount-campaigns">Discount Campaigns</a>
            <a class="dropdown-item" appRequirePermission="admin.all,affiliates.read" routerLink="/affiliates/report">
              Affiliate report
            </a>
            <a class="dropdown-item" appRequirePermission="admin.all" routerLink="/application-without-confirmed-otp">
              Application without confirmed otp
            </a>
          </div>
        </li>
        <li class="nav-item dropdown" ngbDropdown>
          <a class="nav-link dropdown-toggle" id="system" ngbDropdownToggle>System</a>
          <div class="dropdown-menu" aria-labelledby="workspace" ngbDropdownMenu>
            <a class="dropdown-item" routerLink="/product-configuration" appRequirePermission="admin.all,product-configuration.read">
              Product configuration
            </a>
            <a class="dropdown-item" appRequirePermission="admin.all,system-configuration.read" routerLink="/system-configuration">
              System configuration
            </a>
            <a class="dropdown-item" appRequirePermission="admin.all,risk-evaluation-configuration.read"
               routerLink="/risk-evaluation-configs">
              Risk evaluation flows
            </a>
            <a class="dropdown-item" appRequirePermission="admin.all,debt-step-configuration.read,debt-step-configuration.edit"
               routerLink="/debt-step-configs">
              Debt steps configuration
            </a>
            <a class="dropdown-item" appRequirePermission="admin.all,notifications.read" routerLink="/notification-configs">
              Notification configuration
            </a>
            <a class="dropdown-item" appRequirePermission="admin.all,message-templates.read" routerLink="/message-templates">
              Message Templates
            </a>
            <a class="dropdown-item" appRequirePermission="admin.all,file-templates.read" routerLink="/file-templates">File Templates</a>
            <a class="dropdown-item" appRequirePermission="admin.all,affiliates.read" routerLink="/affiliates">
              Affiliate configurations
            </a>
            <a class="dropdown-item" appRequirePermission="admin.all" routerLink="/tariff">
              Tariff configurations
            </a>
            <a class="dropdown-item" appRequirePermission="admin.all" routerLink="/vas">
              VAS definitions
            </a>
            <a class="dropdown-item" appRequirePermission="admin.all" routerLink="/vas/vendors">
              VAS vendors
            </a>
            <a class="dropdown-item" appRequirePermission="admin.all" routerLink="/batch-job-executions">Batch job executions</a>
            <a class="dropdown-item" appRequirePermission="admin.all,loan-sale.read" routerLink="/loan-sale/presale">Loan pre-sell</a>
            <a class="dropdown-item" appRequirePermission="admin.all,loan-sale.read" routerLink="/loan-sale">Loan sale</a>
            <a class="dropdown-item" appRequirePermission="admin.all,payments.import-export" routerLink="/payments-import-export">Payments import/export</a>
            <a class="dropdown-item" appRequirePermission="admin.all,loan-sale-partner.read" routerLink="/sale-partners">Sale partners</a>
            <a class="dropdown-item" appRequirePermission="admin.all" routerLink="/kir">KIR reports</a>
            <a class="dropdown-item" routerLink="/operation-logs">Operation log</a>
            <a class="dropdown-item" routerLink="/creditg-operation-logs">Creditg operation log</a>
            <a class="dropdown-item" routerLink="/creditg-run-entries">Creditg entries</a>
            <a class="dropdown-item" appRequirePermission="admin.all,users.read" routerLink="/users">Users</a>
            <a class="dropdown-item" appRequirePermission="admin.all,roles.read" routerLink="/users/roles">Roles</a>
            <a class="dropdown-item" appRequirePermission="admin.all,authority.read" routerLink="/users/authorities">Authorities</a>
            <a class="dropdown-item" appRequirePermission="admin.all" routerLink="/translation-manager">Translation manager</a>
            <a class="dropdown-item" routerLink="/about-app">About</a>
          </div>
        </li>
        <li>
          <form class="form-inline my-2 my-lg-0 mx-2" role="search">
            <client-lookup (clientSelected)="openClient($event)"></client-lookup>
          </form>
        </li>
        <li>
          <form class="form-inline my-2 my-lg-0 mx-2" role="search">
            <loan-lookup (loanSelected)="openLoan($event)"></loan-lookup>
          </form>
        </li>
      </ul>

      <div class="nav navbar-nav my-2 my-lg-0" ngbDropdown>
        <a class="nav-link dropdown-toggle" id="user-language" ngbDropdownToggle>{{ 'admin.langCode' | translate }}</a>
        <div class="dropdown-menu" aria-labelledby="workspace" ngbDropdownMenu>
          <button class="dropdown-item" *ngFor="let lang of getLangs()" (click)="loadLanguage(lang);">
            {{lang}}
          </button>
        </div>
      </div>

      <div class="nav navbar-nav my-2 my-lg-0" ngbDropdown>
        <a class="nav-link dropdown-toggle" id="client-theme" ngbDropdownToggle><bi name="brush"></bi></a>
        <div class="dropdown-menu" aria-labelledby="workspace" ngbDropdownMenu>
          <button class="dropdown-item" (click)="loadStyle('default')">Default</button>
          <button class="dropdown-item" (click)="loadStyle('light')">Light</button>
          <button class="dropdown-item" (click)="loadStyle('dark')">Dark</button>
        </div>
      </div>

      <ul class="nav navbar-nav my-2 my-lg-0">
        <li class="nav-item"><bi name="person-fill"></bi><span class="navbar-text">{{userName}}</span></li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/logout">Logout</a>
        </li>
      </ul>

    </div>

  </nav>
    <view-history></view-history>
</div>

<div class="main-content">
  <router-outlet></router-outlet>
</div>
