<form [formGroup]="form">
  <div class="modal-header">
    <h4 class="modal-title">{{title}} #{{id}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close(false)">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label for="bookingDate" class="col-sm-4 control-label">Date<sup class="text-danger">*</sup></label>
      <div class="col-sm-8 input-group">
        <input class="form-control form-control-sm"
               id="bookingDate"
               formControlName="bookingDate"
               placeholder="yyyy-mm-dd"
               ngbDatepicker
               #bookingDate="ngbDatepicker">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="bookingDate.toggle()" type="button"></button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.close(false)">Close</button>
    <button type="submit" class="btn btn-outline-danger" [disabled]="!form.valid" (click)="submitForm()">Save</button>
  </div>
</form>

