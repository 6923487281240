import { Component, OnInit } from '@angular/core';
import {
  AlertsService,
  ConfirmDialogueComponent,
  LoanSaleDataService,
  NamedComponent,
  ProductConfigService, TwinoDateAdapterService,
  Utils
} from '@backoffice-monorepo/commons';
import {
  FilterOperation, LoanAgreementType,
  LoanSaleDataResponseSearchResult,
  LoanSaleSearchQuery
} from '@twino/backoffice-api';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateAdapter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take, takeUntil } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'backoffice-monorepo-loan-presale-data-list',
  templateUrl: './loan-presale-data-list.component.html',
  styleUrls: ['./loan-presale-data-list.component.scss'],
  providers: [
    {provide: NgbDateAdapter, useClass: TwinoDateAdapterService}
  ]
})
export class LoanPresaleDataListComponent extends NamedComponent implements OnInit {
  public readonly FilterOperation = FilterOperation;
  currentPage = 1;
  itemsPerPage = 20;
  loanSaleSearchQuery: LoanSaleSearchQuery = {preSaleDateFrom: null, preSaleDateTo: null, agreementTypes: null, brand: null};
  response: LoanSaleDataResponseSearchResult;
  readonly AgreementType = LoanAgreementType;
  public productBrandsList$: Observable<string[]>
  brandType = new FormControl('');
  checkedLoans: number[] = [];
  selectedAgreements: string[] = [];
  preSaleDateForm: FormGroup;
  checkAllPerPage: boolean[] = [];


  constructor(
    protected router: Router,
    private loanSaleDataService: LoanSaleDataService,
    private productConfigService: ProductConfigService,
    activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private alertService: AlertsService
  ) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    this.preSaleDateForm = this.formBuilder.group({
      preSaleDateFrom: [''],
      preSaleDateTo: [''],
    });
    this.productBrandsList$ = this.productConfigService.listBrands();
    this.buildLoanSearchQuery();
    this.refresh();
    this.brandType.valueChanges.subscribe(brandType => {
      this.loanSaleSearchQuery.brand = brandType;
      this.refresh();
    })
    this.preSaleDateForm.get("preSaleDateFrom").valueChanges.subscribe(() => {
      this.loanSaleSearchQuery.preSaleDateFrom = this.preSaleDateForm.get("preSaleDateFrom").value;
      this.refresh();
    })
    this.preSaleDateForm.get("preSaleDateTo").valueChanges.subscribe(() => {
      this.loanSaleSearchQuery.preSaleDateTo = this.preSaleDateForm.get("preSaleDateTo").value;
      this.refresh();
    })
  }

  buildLoanSearchQuery(){
    this.loanSaleSearchQuery.pageSize = this.itemsPerPage;
    this.loanSaleSearchQuery.page = this.currentPage;
  }

  refresh() {
    this.loanSaleSearchQuery.page = this.currentPage;
    this.loanSaleDataService.list(this.loanSaleSearchQuery).subscribe(data => {
      this.response = data;
    })
  }

  resetFilters() {
    this.loanSaleSearchQuery.preSaleDateFrom = null;
    this.loanSaleSearchQuery.preSaleDateTo = null;
    this.loanSaleSearchQuery.agreementTypes = null;
    this.loanSaleSearchQuery.brand = null;
    this.brandType.setValue(null);
    this.selectedAgreements = [];
    this.preSaleDateForm.reset();
    this.checkedLoans = [];
    this.checkAllPerPage = [];
    this.refresh();
  }

  pageChange($event?: number | null) {
    if ($event) this.currentPage = $event;
    this.refresh();
  }

  navigateTo(id: string | number) {
    this.router.navigate([`/loan-sale/${id}`]).then();
  }

  getName() {
    return 'Loan pre-sell';
  }

  removeFromPresale(id: number) {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = `Remove loan from pre-sell list`;
    modalRef.componentInstance.content = `Are you sure you want to delete loan #${id}?`;
    modalRef.result.then(
      (result) => {
        if (result === true) {
          this.loanSaleDataService.deleteLoanFromLoanSaleData(id).pipe(
            takeUntil(this.$destroy)
          ).subscribe(() => {
            this.alertService.notifySuccess("Loan removed from pre-sell list");
            this.refresh();
          });
        }
      }
    );
  }

  setPreSoldStatus() {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = `Set pre-sold status`;
    modalRef.componentInstance.content = `Are you sure you want to set pre-sold status?`;
    modalRef.result.then(
      (result) => {
        if (result === true) {
          this.loanSaleDataService.setPreSellStatus({
            agreementType: null,
          }).pipe(
            takeUntil(this.$destroy)
          ).subscribe(() => {
            this.alertService.notifySuccess("All loans are set to pre-sold status");
            this.refresh();
          });
        }
      }
    )
  }

  preSellLoans() {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = `Pre-sell loans`;
    modalRef.componentInstance.content = `Are you sure you want to pre-sell these loans?`;
    modalRef.result.then(
      (result) => {
        if (result === true) {
          const loanSearchQuery = {...this.loanSaleSearchQuery,
            pageSize: this.response.totalRecords ? this.response.totalRecords : 1  } as LoanSaleSearchQuery;
          this.loanSaleDataService.list(loanSearchQuery)
            .pipe(take(1))
            .subscribe(response => {
              const loanIds = (this.checkedLoans.length > 0) ? this.checkedLoans : response.results.map(loan => loan.loanId);
              this.loanSaleDataService.preSell(loanIds).pipe(
                takeUntil(this.$destroy)
              ).subscribe(() => {
                this.alertService.notifySuccess("Loans pre-sold");
                this.checkedLoans = [];
                this.checkAllPerPage = [];
                this.refresh();
              })
            });
        }
      }
    )
}

  generateReport() {
    this.loanSaleDataService.generatePreviewPreSellReport({agreementTypes: this.loanSaleSearchQuery.agreementTypes}).pipe(
      takeUntil(this.$destroy)
    ).subscribe((response: Blob) => {
        Utils.downloadBlob(response, "preSaleReport.csv");
      },
      error => console.error("Error downloading the file.", error))
  }

  checkLoan(event: any, loanId: number) {
    if(event.target.checked ===  true) {
      this.checkedLoans.push(loanId);
    } else {
      const index = this.checkedLoans.indexOf(loanId, 0);
      if (index > -1) {
        this.checkedLoans.splice(index, 1);
      }
    }
  }

  toggleCheckAll(event: any) {
    if (event.target.checked === true) {
      this.checkAllPerPage[this.currentPage] = true;
      this.response?.results.map(a => this.checkedLoans.push(a.loanId));
    } else {
      this.checkAllPerPage[this.currentPage] = false;
      this.response?.results.forEach(item => this.checkedLoans.splice(this.checkedLoans.indexOf(item.loanId), 1));
    }
  }

  isChecked(loanId: number): boolean {
    return this.checkedLoans.includes(loanId);
  }

  checkAgreement(agreement: string) {
    const index = this.selectedAgreements.indexOf(agreement, 0);
    if (index > -1) {
      this.selectedAgreements.splice(index, 1);
    } else {
      this.selectedAgreements.push(agreement);
    }
    this.loanSaleSearchQuery.agreementTypes = this.selectedAgreements;
    this.refresh();
  }

  isCheckedAgreementType(id: string) {
    const index = this.selectedAgreements.indexOf(id, 0);
    return (index > -1);
  }

}
