import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { SalePartnersListComponent } from './components/sale-partners-list/sale-partners-list.component';
import { SalePartnerUpdateComponent } from './components/sale-partner-update/sale-partner-update.component';

const routes: Routes = [
  {path: '', component: SalePartnersListComponent, pathMatch: 'full'},
  {path: ':id', component: SalePartnerUpdateComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SalePartnersRoutingModule {
}
