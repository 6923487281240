<transform-table [transformInstructions]="loanTransformations" [entity]="data"></transform-table>
<table class="table table-striped table-hover table-sm mb-0">
  <colgroup>
    <col class="table-primary"/>
    <col/>
  </colgroup>
  <tbody>
  <tr>
    <td class="text-right w-50">DiPocket Balance</td>
    <td>{{diPocketBalance}}</td>
  </tr>
  <tr>
    <td class="text-right w-50">Is flagged for restructuring</td>
    <td>{{restructuringAvailability?.isFlaggedForRestructuring}}</td>
  </tr> 
  <tr>
    <td class="text-right w-50">Loan can be restructured</td>
    <td>{{restructuringAvailability?.loanCanBeRestructured}}</td>
  </tr>
  </tbody>
</table>
