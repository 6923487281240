import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorsService } from './errors.service';
import { Observable } from 'rxjs';
import {
  Application,
  Client,
  ClientCommentRequest,
  ClientDetails,
  ClientEditBillingDetailsRequest,
  ClientEmploymentInfoRequest,
  ClientIncomeDataRequest,
  EnumResponse,
  FilterOperation,
  FullClient,
  JoinStrategy,
  SearchQuery,
  SearchQueryAware,
  SearchQueryBuilder,
  SearchResponse,
  ClientBalanceResponse,
  CommunicationRequest,
  UpdateClientPersonalInformationRequest,
  UpdateClientPersonalIdAndBirthdateRequest,
  UpdateClientMobilePhoneRequest,
  UpdateClientEmailRequest,
  UpdateClientConsentsRequest,
  ClientConsent,
  AddDiscountCampaignToClientRequest,
  SearchCsvExportRequest, UpdateAddressRequest
} from '@backoffice-monorepo/api';
import { Address, BillingDetail, ClientComment, EmploymentInfo, IncomeInfo } from '@twino/backoffice-api';
import { FileUpload } from '../..';

@Injectable({
  providedIn: 'root'
})
export class ClientService extends BaseService implements SearchQueryAware<Client> {

  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  findClientsByText(searchKey: string, searchValue: string): Observable<{ results: never[] }>  {
    const searchQuery = new SearchQueryBuilder()
      .withPageSize(5);
    if (searchKey === 'fullName') {
      const splitTerm = searchValue.split(/\s+/);
      let firstName: string, lastName: string;
      if (/\s/g.test(searchValue)) {
        searchQuery.withJoinStrategy(JoinStrategy.AND);
        firstName = splitTerm[0]; lastName = splitTerm[1];
      } else {
        searchQuery.withJoinStrategy(JoinStrategy.OR);
        firstName = lastName = searchValue;
      }
      searchQuery.addFilterData({
        propertyName: 'firstName',
        operation: FilterOperation.CONTAINS,
        values: [firstName]
      }).addFilterData({
        propertyName: 'lastName',
        operation: FilterOperation.CONTAINS,
        values: [lastName]
      })
    } else {
      searchQuery.addFilterData({
        propertyName: searchKey,
        operation: FilterOperation.CONTAINS,
        values: [searchValue]
      });
    }
    return this.post(`/api/clients/search`, searchQuery.build());
  };

  find<L>(searchQuery: SearchQuery): Observable<SearchResponse<L>> {
    return this.post(`/api/clients/list`, searchQuery);
  }

  listEnumValues(enumClass: string): Observable<EnumResponse> {
    return this.get<EnumResponse>(`/api/clients/enum-values/${enumClass}`);
  }

  exportCsv(exportRequest: SearchCsvExportRequest): Observable<Blob> {
    return this.post(`/api/clients/export-csv`, exportRequest, {'responseType': 'blob'});
  }

  getClient(id: number): Observable<FullClient> {
    return this.get(`/api/client/${id}`);
  }

  getClientSurplusBalance(id: number): Observable<ClientBalanceResponse> {
    return this.get(`/api/client/${id}/balance`);
  }

  getClientLastApplication(id: number): Observable<Application> {
    return this.get(`/api/client/${id}/last-application`, {}, true, 404);
  }

  getClientAddresses(id: number): Observable<Array<Address>> {
    return this.get(`/api/client/${id}/addresses`);
  };

  getClientDetails(id: number): Observable<ClientDetails> {
    return this.get(`/api/client/${id}/details`);
  };

  getClientEmploymentInfo(id: number): Observable<EmploymentInfo> {
    return this.get(`/api/client/${id}/employment-info`);
  };

  getClientIncomeInfo(id: number): Observable<IncomeInfo> {
    return this.get(`/api/client/${id}/income-info`);
  };

  getClientBillingDetails(id: number): Observable<BillingDetail[]> {
    return this.get(`/api/client/${id}/billing-details`);
  };

  getClientConsents(clientId: number): Observable<Array<ClientConsent>> {
    return this.get(`/api/client/${clientId}/consents`);
  };

  updateClientConsents(clientId: number, consentsRequest: UpdateClientConsentsRequest) {
    return this.post(`/api/client/${clientId}/consents`, consentsRequest);
  };

  updateClientDetails(clientId: number, clientEditDetails: ClientDetails) {
    return this.post(`/api/client/${clientId}/details`, clientEditDetails);
  };

  updateClientIncomeData(clientId: number, clientEditIncomeRequest: ClientIncomeDataRequest) {
    return this.post(`/api/client/${clientId}/income-info`, clientEditIncomeRequest);
  };

  updateClientBillingData(clientId: number, clientEditBillingRequest: ClientEditBillingDetailsRequest, billingDetailId: number) {
    return this.post(`/api/client/${clientId}/billing-details/${billingDetailId}`, clientEditBillingRequest);
  };

  updateClientEmploymentInfo(clientId: number, clientEditEmploymentRequest: ClientEmploymentInfoRequest) {
    return this.post(`/api/client/${clientId}/employment-info`, clientEditEmploymentRequest);
  };

  updateClientAddress(clientId: number, clientEditAddress: UpdateAddressRequest) {
    return this.post(`/api/client/${clientId}/address`, clientEditAddress);
  };

  addClientComment(clientId: number, clientCommentRequest: ClientCommentRequest) {
    return this.post(`/api/client/${clientId}/add-comment`, clientCommentRequest);
  };

  sendClientCommunication(clientId: number, clientCommunicationRequest: CommunicationRequest) {
    return this.post(`/api/client/${clientId}/communication-send`, clientCommunicationRequest);
  }

  sendEmail(clientId: number, communicationRequest: CommunicationRequest, attachments: Array<FileUpload>) {
    const formData = new FormData();

    formData.append(
      'email',
      new Blob(
        [JSON.stringify(communicationRequest)],
        {type: "application/json"}
      )
    );

    attachments.forEach(attachment => {
      formData.append('files', attachment.file);
    });

    return this.post(`/api/client/${clientId}/email-send`, formData);
  }

  updateClientPersonalInfo(clientId: number, clientEditRequest: UpdateClientPersonalInformationRequest): Observable<void> {
    return this.patch(`/api/client/${clientId}/personal-info`, clientEditRequest);
  };

  updateClientPersonalIdAndBirthDate(clientId: number, clientEditRequest: UpdateClientPersonalIdAndBirthdateRequest): Observable<void> {
    return this.patch(`/api/client/${clientId}/personal-id-birthdate`, clientEditRequest);
  };

  updateClientPhone(clientId: number, clientEditRequest: UpdateClientMobilePhoneRequest): Observable<void> {
    return this.patch(`/api/client/${clientId}/phone`, clientEditRequest);
  };

  updateClientEmail(clientId: number, clientEditRequest: UpdateClientEmailRequest): Observable<void> {
    return this.patch(`/api/client/${clientId}/email`, clientEditRequest);
  };

  assignDiscountCampaignToClient(clientId: number, addDiscountCampaignToClientRequest: AddDiscountCampaignToClientRequest): Observable<void> {
    return this.post(`/api/client/${clientId}/discount-campaign`, addDiscountCampaignToClientRequest);
  }

  searchClientComments(searchQuery: SearchQuery): Observable<SearchResponse<ClientComment>> {
    return this.post(`/api/client/comments/search`, searchQuery);
  }
}
