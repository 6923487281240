import { FeeType } from './fee.type';

export class TransactionEntry {
  id: number
  transactionType: string
  accountType: string
  accountSubtype?: string
  feeType?: FeeType
  bookingDate: Date
  transactionDateTime: Date
  amount: number
  details: string
  loanId?: number
  clientId?: number
  scheduleItemUUID: string
}
