import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertsService, BasicComponent, ClientService } from '@backoffice-monorepo/commons';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Address } from '@twino/backoffice-api';
import { ClientsRefreshService } from '../../services/clients-refresh.service';
import { concatMap, takeUntil } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'client-edit-address',
  templateUrl: './client-address-edit.component.html',
  styleUrls: ['./client-address-edit.component.scss']
})
export class ClientAddressEditComponent extends BasicComponent implements OnInit {
  @Input() clientId: number;

  clientAddressEditForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private alertService: AlertsService,
    private clientService: ClientService,
    private formBuilder: FormBuilder,
    private clientsRefreshService: ClientsRefreshService
  ) {
    super();
  }

  activeTabItem = 0;
  tabsTitles = [];


  ngOnInit(): void {
    this.clientAddressEditForm = this.formBuilder.group({
      addressTabs: this.formBuilder.array([])
    });
    this.clientService.getClientAddresses(this.clientId).subscribe(addressList => {
      const formControl = this.clientAddressEditForm.get('addressTabs') as FormArray;

      addressList.forEach((address) => {
        const formGroupAddress = this.createForm();

        if (address) {
          this.tabsTitles.push(address.type.toLowerCase());

          const editAddress = {
            type: address.type,
            postalCode: address.postalCode,
            postalCountry: address.postalCountry,
            administrativeArea: address.administrativeArea,
            locality: address.locality,
            dependentLocality: address.dependentLocality,
            addressLine: address.addressLine,
            apartmentNumber: address.apartmentNumber,
            houseNumber: address.houseNumber,
            street: address.street,
            active: address.active
          }
          formGroupAddress.setValue(editAddress);
        }
        formControl.push(formGroupAddress);
      });
    })
  }

  get getFormControls() {
    return this.clientAddressEditForm.get('addressTabs') as FormArray;
  }

  createForm(): FormGroup {
    return this.formBuilder.group({
      type: ['', Validators.required],
      postalCode: '',
      postalCountry: ['', Validators.required],
      administrativeArea: '',
      locality: '',
      dependentLocality: '',
      addressLine: '',
      apartmentNumber: null,
      houseNumber: null,
      street: null,
      active: null
    });
  }

  submitForm() {
    const actualAddressData = this.emptyStringToNull(this.clientAddressEditForm.value.addressTabs[0]);
    const declaredAddressData = this.emptyStringToNull(this.clientAddressEditForm.value.addressTabs[1]);
    this.clientService.updateClientAddress(this.clientId, actualAddressData).pipe(
      concatMap(() => declaredAddressData ? this.clientService.updateClientAddress(this.clientId, declaredAddressData) : of({})),
      takeUntil(this.$destroy)
    ).subscribe(() => {
      this.alertService.notifySuccess(`Address updated for client ${this.clientId}`);
      this.clientsRefreshService.reloadClientAddressModel();
      this.activeModal.close();
    });
  }

  emptyStringToNull(addressData: Address): Address {
    addressData.street = (addressData.street === '') ? null : addressData.street;
    addressData.apartmentNumber = (addressData.apartmentNumber === '') ? null : addressData.apartmentNumber;
    addressData.houseNumber = (addressData.houseNumber === '') ? null : addressData.houseNumber;
    return addressData;
  }

}
