import { LoanAgreementType } from './agreement';
import { SortingCriterion } from './search/sorting';

export interface LoanSaleDataResponse {
  id: number
  loanId: number
  preSaleDate?: number
  saleDate?: number
  totalAmount: number
  saleAmount?: number
  dpd?: number
  portfolioId?: number
  agreementType: LoanAgreementType
  created: Date
  brand?: string
}

export interface  LoanSaleDataResponseSearchResult{
  results: LoanSaleDataResponse[]
  totalRecords: number
  totalPages: number
  currentPage: number
  totalAmountSum: number
  loansCount: number
  reportDate: number
  averageTotalAmount: number
}

export interface LoanSalePortfolio {
  id: number
  status: LoanSalePortfolioStatus
  loanSalePartnerId?: number
  loanSalePartnerName: string
  presaleFileExternalId?: number
  saleFileExternalId?: string
  totalAmount: number
  saleAmount?: number
  preSaleDate?: Date
  saleDate?: Date
  loansCount: number
}

export enum LoanSalePortfolioStatus {
  PRE_SOLD = 'PRE_SOLD',
  SOLD = 'SOLD',
  CANCELED = 'CANCELED',
}

export interface SellLoanPortfolioRequest {
  portfolioId: number
  saleAmount: number
  onDate: Date
  partnerId: number
}

export interface RevertSellingPortfolioRequest {
  portfolioId: number
}

export interface MoveLoanToAnotherPortfolioRequest {
  portfolioId: number,
  loanIds: number[]
}

export interface RemoveLoanFromPortfolioRequest {
  loanIds: number[]
}

export interface LoanSaleFileSignedUrlResponse {
  archivePassword: string
  saleFileSignedUrl: string
}

export interface LoanSaleSearchQuery {
  page?: number
  pageSize?: number
  loanId?: number | null
  agreementTypes?: string[] | null
  brand?: string | null
  preSaleDateFrom?: Date | null
  preSaleDateTo?: Date | null
  sortingCriterion: SortingCriterion
}

export interface LoanPreSaleSearchQuery {
  loanId?: number | null
  agreementType?: LoanAgreementType | null
}

export interface LoanSaleDataAmountsResponse{
  totalAmountSum: number
  loansCount: number
  averageTotalAmount: number
}

export interface LoanSaleDataAmountsRequest{
  loanIds: number[]
}

export interface CreateLoanPortfoliosByPartnersRequest {
  loanIds: number[]
}

export interface LoanSaleDataPreSellRequest {
  loanIds: number[]
}
