import { Injectable } from '@angular/core';
import { BaseService, ErrorsService } from '@backoffice-monorepo/commons';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApplicableTariffsResponse, TariffActivationRequest, TariffResponseClient } from '../api/tariff-client';

@Injectable({
  providedIn: 'root'
})
export class PlPiTariffClientService extends BaseService  {
  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }


  listTariffClient(clientId: number): Observable<ApplicableTariffsResponse> {
    return this.get(`/api/pl/tariffs/${clientId}/list-applicable`);
  }

  getActiveTariff(clientId: number): Observable<TariffResponseClient> {
    return this.get(`/api/pl/tariff/active/${clientId}`);
  }

  activateTariff(request: TariffActivationRequest): Observable<unknown> {
    return this.post(`/api/pl/tariff/activate`, request);
  }

  cancelTariff(clientId: number) {
    return this.post(`/api/pl/tariff/cancel-next-active-tariff/client/${clientId}`);
  }

}
