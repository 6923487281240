<div class="modal-header">
  <h4 class="modal-title">Move Loan to another portfolio</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <label for="portfolioId" class="col-sm-4 control-label">Enter portfolio Id<sup class="text-danger">*</sup></label>
  <div class="col-sm-8">
    <input type="number" class="form-control form-control-sm" id="portfolioId" [formControl]="portfolioId" name="portfolioId">
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="activeModal.close(false)">Close</button>
  <button type="submit" class="btn btn-outline-danger" (click)="submit()" [disabled]="portfolioId.invalid">Save</button>
</div>
