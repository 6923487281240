import { Component, OnInit } from '@angular/core';
import { BasicComponent, LoanSidebarComponent } from '@backoffice-monorepo/commons';
import { FullLoan } from '@twino/backoffice-api';
import { PlPiRestructuringService } from '../../services/pl-pi-restructuring.service';
import { RestructuringIntentionResponse, RestructuringStateResponseBackoffice, RestructuringStateType } from '../../api/restructuring';
import { take } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-restructuring-sidebar',
  templateUrl: './restructuring-sidebar.component.html',
  styleUrls: ['./restructuring-sidebar.component.css']
})
export class RestructuringSidebarComponent extends BasicComponent implements LoanSidebarComponent, OnInit {

  loan: FullLoan;
  activeIntention: RestructuringIntentionResponse;
  restructuringState: RestructuringStateResponseBackoffice;
  restructuringStateType = RestructuringStateType;

  constructor(
    private restructuringService: PlPiRestructuringService,
  ) {
    super();
  }

  ngOnInit() {
    this.getRestructuringState();
  }

  refresh() {
    this.restructuringService.getActiveRestructuringIntention(this.loan.id)
      .pipe(take(1))
      .subscribe(state => {
      this.activeIntention = state;
    })
  }

  getRestructuringState() {
    this.restructuringService.getCurrentRestructuringState(this.loan.id)
      .pipe(take(1))
      .subscribe(state => {
      this.restructuringState = state;
      if(this.restructuringState.stateType === this.restructuringStateType.RESTRUCTURING_INTENTION_ACTIVE) {
        this.refresh();
      }
    })
  }
}
