import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertsService, BasicComponent } from '@backoffice-monorepo/commons';
import { PlPiRestructuringService } from '../../services/pl-pi-restructuring.service';
import {
  CalculateRestructuringMonthlyAmountRequest,
  CreateRestructuringIntentionRequest,
  RestructuringStateResponseBackoffice
} from '../../api/restructuring';
import { FullLoan } from '@twino/backoffice-api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'backoffice-monorepo-restructuring-calculator',
  templateUrl: './restructuring-calculator.component.html',
  styleUrls: ['./restructuring-calculator.component.scss']
})
export class RestructuringCalculatorComponent extends BasicComponent implements OnInit {
  @Input() loan: FullLoan;
  @Input() state: RestructuringStateResponseBackoffice;
  step = 1;
  form: FormGroup;
  monthlyPayment = 0;

  constructor(
    public activeModal: NgbActiveModal,
    private alertService: AlertsService,
    private restructuringService: PlPiRestructuringService,
    private formBuilder: FormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    this.monthlyPayment = this.state.monthlyPayment;
    this.form = this.formBuilder.group({
      firstRepaymentDate: ['25', Validators.required],
      installmentsNumber: [2, Validators.required],
    });
  }

  sliderMove() {
    const request: CalculateRestructuringMonthlyAmountRequest = {
      openAmount: this.state.loanOpenAmount,
      term: {
        value: this.form.controls.installmentsNumber.value,
        unit:'MONTHS'
      }
    }
    this.restructuringService.calculateMonthlyAmount(request).subscribe(response => {
      this.monthlyPayment = response.monthlyAmount;
    })
  }

  createIntention() {
    const request: CreateRestructuringIntentionRequest = {
      loanId: this.loan.id,
      firstRepaymentDayOfMonth: +this.form.controls.firstRepaymentDate.value,
      term: {
        value: this.form.controls.installmentsNumber.value,
        unit: 'MONTHS'
      }
    }
    this.restructuringService.createRestructuringIntention(request).pipe(
      takeUntil(this.$destroy)
    )
      .subscribe(() =>  {
        this.alertService.notifySuccess(`Intention created successfully!`);
        this.activeModal.close(true);
      });
  }
}
