import { Injectable } from '@angular/core';
import { BaseService, ErrorsService } from '@backoffice-monorepo/commons';
import { HttpClient } from '@angular/common/http';
import { EnumResponse, SearchQuery, SearchQueryAware, SearchResponse } from '@twino/backoffice-api';
import { Observable } from 'rxjs';
import {
  BuyBackRequest,
  ChangeManualDcStatusRequest,
  DebtStageResponse,
  DebtStageType,
  MarkLoanAsFraudRequest, RemoveFromSaleRequest,
  SetDebtStageRequest
} from '../api/debt-stage';

@Injectable({
  providedIn: 'root'
})
export class PlPiDebtCollectionStagesService extends BaseService implements SearchQueryAware<DebtStageResponse> {
  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  find(searchQuery: SearchQuery): Observable<SearchResponse<DebtStageResponse>> {
    return this.post(`/api/pl/dc/stages/list`, searchQuery);
  }

  listEnumValues(enumClass: string): Observable<EnumResponse> {
    return this.get<EnumResponse>(`/api/pl/dc/stages/enum-values/${enumClass}`);
  }

  getLoanStages(loanId: number): Observable<DebtStageResponse> {
    return this.get<DebtStageResponse>(`/api/pl/dc/loan/${loanId}/stages`);
  }

  setLoanDcStage(loanId: number, setDebtStageRequest: SetDebtStageRequest): Observable<never> {
    return this.post(`/api/pl/dc/loan/${loanId}/stages`, setDebtStageRequest);
  }

  closeLoanDcStage(loanId: number, stageType: DebtStageType): Observable<never> {
    return this.put(`/api/pl/dc/loan/${loanId}/stages/${stageType}/close`, {});
  }

  closeAnyActiveLoanStage(loanId: number): Observable<never> {
    return this.put(`/api/pl/dc/loan/${loanId}/stages/close`, {});
  }

  markLoanAsPolice(loanId: number, changeManualDcStatusRequest: ChangeManualDcStatusRequest): Observable<never> {
    return this.put(`/api/pl/dc/loan/${loanId}/police`, changeManualDcStatusRequest);
  }

  markLoanAsFraud(loanId: number, markLoanAsFraudRequest: MarkLoanAsFraudRequest): Observable<never> {
    return this.put(`/api/pl/dc/loan/${loanId}/fraud`, markLoanAsFraudRequest);
  }

  markLoanAsDeceased(loanId: number, changeManualDcStatusRequest: ChangeManualDcStatusRequest): Observable<never> {
    return this.put(`/api/pl/dc/loan/${loanId}/deceased`, changeManualDcStatusRequest);
  }

  markLoanAsInsolvency(loanId: number, changeManualDcStatusRequest: ChangeManualDcStatusRequest): Observable<never> {
    return this.put(`/api/pl/dc/loan/${loanId}/insolvency`, changeManualDcStatusRequest);
  }

  removeFromSale(changeManualDcStatusRequest: RemoveFromSaleRequest): Observable<never> {
    return this.put(`/api/pl/dc/loan/remove-from-sale`, changeManualDcStatusRequest);
  }

  preBuyBack(loanId: number, changeManualDcStatusRequest: ChangeManualDcStatusRequest): Observable<never> {
    return this.put(`/api/pl/dc/loan/${loanId}/pre-buy-back`, changeManualDcStatusRequest);
  }

  buyBack(loanId: number, changeManualDcStatusRequest: BuyBackRequest): Observable<never> {
    return this.put(`/api/pl/dc/loan/${loanId}/buy-back`, changeManualDcStatusRequest);
  }

  addLoanForPresale(loanId: number): Observable<never> {
    return this.post(`/api/pl/loan/${loanId}/presale`)
  }

  importStatement(file: File): Observable<void> {
    const formData = new FormData();
    formData.append(
      'request',
      new Blob(
        [],
        {type: "application/json"}
      )
    );
    formData.append('file', file);
    return this.post(`/api/pl/dc/loan/import/buy-back`, formData);
  }
}
