<aside class="container-fluid row">
  <div class="mt-1 d-flex">
    <span>
      <server-refresh (refresh)="refresh()"></server-refresh>
    </span>
   <span class="ml-2">
        <button type="button" class="btn btn-info">
          Portfolio value <span class="badge badge-light">{{totalAmountSum}}</span>
        </button>
    </span>

    <span class="ml-2">
        <button type="button" class="btn btn-info">
          Number of loans <span class="badge badge-light">{{loansCount}}</span>
        </button>
    </span>

    <span class="ml-2">
        <button type="button" class="btn btn-info">
          Average total <span class="badge badge-light">{{averageTotalAmount}}</span>
        </button>
    </span>

    <span class="ml-2">
        <button type="button" class="btn btn-success" (click)="generateReport()" title="Generate pre-sell report">
          Report
        </button>
    </span>

    <span class="ml-2">
        <button type="button" class="btn btn-warning" (click)="setPreSoldStatus()" title="Set Pre_Sold status">
          Set status
        </button>
    </span>

    <span class="ml-2">
        <button type="button" class="btn btn-warning" (click)="preSellLoans()">
          Pre-Sell
        </button>
    </span>
    <span class="ml-2">
        <button type="button" class="btn btn-warning" (click)="createPortfolio()"
                title="create loan sale portfolio for each partner enabled for portfolio auto assignment">
          Create portfolio
        </button>
    </span>
    <span class="ml-2">
        <button type="button" class="btn btn-danger" (click)="removeFromPresale(0)"
                title="Remove selected loans" [disabled]="checkedLoans.length === 0">
          Remove
        </button>
    </span>
    <span class="ml-2">
        <button type="button" class="btn btn-danger" (click)="resetFilters()" title="Reset filters">
          Reset
        </button>
    </span>
    <span class="ml-2" *ngIf="productBrandsList$ | async as brands">
        <select [formControl]="brandType" class="bg-light form-control form-control-sm h-100">
           <option value="" selected disabled hidden>Filter by brand</option>
           <option *ngFor="let key of brands" [ngValue]="key">{{ key }}</option>
         </select>
    </span>
  </div>
  <div class="ml-2 mt-1">
    <div ngbDropdown>
      <button type="button" class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
        <span *ngIf="selectedAgreements.length !== 0"> Selected agreements {{selectedAgreements.length}}</span>
        <span *ngIf="selectedAgreements.length === 0"> Select agreement</span>
      </button>

      <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="checkbox_dropdown_ct">
        <div class="checkbox_list" *ngFor="let item of AgreementType | keyvalue"
             (click)="checkAgreement(item.key)">
          <input type="checkbox" [checked]="isCheckedAgreementType(item.key)">
          <span>{{item.value}}</span>
        </div>
      </div>
    </div>
  </div>
<ng-container [formGroup]="preSaleDateForm">
  <div class="input-group col-md mt-1">
    <input class="form-control"
           id="preSaleDateFrom"
           placeholder="From"
           formControlName="preSaleDateFrom"
           ngbDatepicker
           #preSaleDateFrom="ngbDatepicker">
    <div class="input-group-append">
      <button class="btn btn-outline-secondary btn-sm calendar" (click)="preSaleDateFrom.toggle()" type="button"></button>
    </div>
  </div>

  <div class="input-group col-md mt-1">
    <input class="form-control"
           id="preSaleDateTo"
           placeholder="To"
           formControlName="preSaleDateTo"
           ngbDatepicker
           #preSaleDateTo="ngbDatepicker">
    <div class="input-group-append">
      <button class="btn btn-outline-secondary btn-sm calendar" (click)="preSaleDateTo.toggle()" type="button"></button>
    </div>
  </div>
</ng-container>
</aside>

<table class="table table-striped table-hover mt-1">
  <thead class="text-light bg-primary">
  <tr>
    <th></th>
    <th sortable="loanId" (sort)="onSort($event)">Loan ID</th>
    <th>
      <input
        appRequirePermission="admin.all,task.read"
        type="checkbox"
        class="btn"
        [checked]="checkAllPerPage[currentPage]"
        (change)="toggleCheckAll($event)">
    </th>
    <th sortable="totalAmount" (sort)="onSort($event)">Total amount</th>
    <th sortable="brand" (sort)="onSort($event)">Brand</th>
    <th sortable="preSaleDate" (sort)="onSort($event)">Pre-sale date</th>
    <th sortable="dpd" (sort)="onSort($event)">DPD</th>
    <th sortable="agreementType" (sort)="onSort($event)">Agreement type</th>
    <th></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let item of response?.results">
    <td></td>
    <td>
      <a routerLink="/loans/{{item.loanId}}">{{item.loanId}}</a>
    </td>
    <td>
      <input type="checkbox" class="btn" (change)="checkLoan($event,item.loanId)" [checked]="isChecked(item.loanId)">
    </td>
    <td>{{item.totalAmount}}</td>
    <td>{{item.brand}}</td>
    <td>{{item.preSaleDate}}</td>
    <td>{{item.dpd}}</td>
    <td>{{item.agreementType}}</td>
    <td>
      <button appRequirePermission="admin.all,loan-sale.edit"
              class="btn btn-danger btn-sm"
              (click)="removeFromPresale(item.loanId)">
        Remove
      </button>
    </td>
  </tr>
  </tbody>
</table>

<nav class="container-fluid row" *ngIf="response">
  <div class="col-md-10">
    <ngb-pagination class="pages"
                    size="sm"
                    [collectionSize]="response?.totalRecords"
                    [page]="currentPage"
                    [pageSize]="itemsPerPage"
                    [maxSize]="10"
                    [rotate]="false"
                    [ellipses]="true"
                    [boundaryLinks]="false"
                    (pageChange)="pageChange($event)">
      <ng-template ngbPaginationPrevious>Previous</ng-template>
      <ng-template ngbPaginationNext>Next</ng-template>
    </ngb-pagination>
  </div>
  <div class="col-md-2 text-right">Total record(s): {{response?.totalRecords}}</div>
</nav>

