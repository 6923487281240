<div class="container-fluid row pt-1 pb-2">
  <p class="ml-3">
    <b>Refund Transactions</b>
  </p>
  <!-- region buttons -->
  <div class="col-md-12 mb-1">
      	<span class="mr-1 float-right">
            <button class="btn btn-success btn-sm"  (click)="refresh()">
              Refresh
            </button>
        </span>
  </div>
  <!-- endregion -->
  <div class="col-md-12">
    <server-table>
      <table list-table class="table table-striped table-hover table-sm">
        <thead class="text-light bg-primary">
        <tr>
          <th>Id</th>
          <th>Loan</th>
          <th>Transaction type</th>
          <th>Fee type</th>
          <th>Account type</th>
          <th>Account subtype</th>
          <th>Booking date</th>
          <th>Amount</th>
          <th>Details</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of searchResults?.results; trackBy: trackById">
          <td>{{ item.id }}</td>
          <td>{{ item.loanId }}</td>
          <td>{{ item.transactionType }}</td>
          <td>{{ item.feeType }}</td>
          <td>{{ item.accountType }}</td>
          <td>{{ item.accountSubtype }}</td>
          <td>{{ item.bookingDate | date: 'dd.MM.yyyy' }}</td>
          <td>{{ item.amount }}</td>
          <td>{{ item.details }}</td>
          <td>
            <button type="button" class="btn btn-outline-danger btn-sm" (click)="refund(item.id)" appRequirePermission="admin.all">Refund</button>
          </td>
        </tr>
        </tbody>
      </table>
    </server-table>
    <div class="form-inline form-group" *ngIf="(searchResults?.totalRecords >= itemsPerPage)">
      <ngb-pagination class="pages"
                      size="sm"
                      [collectionSize]="searchResults?.totalRecords"
                      [page]="searchResults?.currentPage"
                      [pageSize]="itemsPerPage"
                      [maxSize]="10"
                      [rotate]="false"
                      [ellipses]="true"
                      [boundaryLinks]="false"
                      (pageChange)="pageChange($event)">
        <ng-template ngbPaginationPrevious>Previous</ng-template>
        <ng-template ngbPaginationNext>Next</ng-template>
      </ngb-pagination>
    </div>
  </div>
</div>


