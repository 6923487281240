import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { BaseAddress } from '../../../phone-application/api/address';
import { PlPiWaskoService } from '../../../phone-application/services/pl-pi-wasko.service';
import { catchError, distinctUntilChanged, filter, map, pluck, share, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { POSTAL_CODE_MASK } from '../../../../constants/postal-code-mask.const';
import { Address, FullLoan, UpdateAddressRequest } from '@twino/backoffice-api';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertsService, BasicComponent, ClientService } from '@backoffice-monorepo/commons';

@Component({
  selector: 'backoffice-monorepo-restructuring-address',
  templateUrl: './restructuring-address.component.html',
  styleUrls: ['./restructuring-address.component.scss']
})
export class RestructuringAddressComponent extends BasicComponent implements OnInit, OnDestroy {
  @Input() loan: FullLoan;
  @Input() addresses: Address;
  $addressList: Observable<BaseAddress[]>;
  $cityOptions: Observable<string[]>;
  $streetOptions: Observable<string[]>;
  subscription: Subscription;

  addressForm: FormGroup = this.formBuilder.group({
    postalCode: ['', [Validators.required, Validators.pattern(POSTAL_CODE_MASK)]],
    city: ['', Validators.required],
    street: null,
    houseNumber: ['', Validators.required],
    province: [''],
    apartmentNumber: null
  });

  constructor(
    private waskoService: PlPiWaskoService,
    public activeModal: NgbActiveModal,
    private alertService: AlertsService,
    private formBuilder: FormBuilder,
    private clientService: ClientService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.addressForm.get('street').disable();
    const formValues = {
      postalCode: this.addresses[0].postalCode,
      city: this.addresses[0].locality,
      street: this.addresses[0].street,
      houseNumber: this.addresses[0].houseNumber,
      province: this.addresses[0].administrativeArea,
      apartmentNumber: this.addresses[0].apartmentNumber
    }
    this.addressForm.setValue(formValues);

    this.$addressList = this.addressForm.get('postalCode').valueChanges.pipe(
      map((postal: string) => postal.match(POSTAL_CODE_MASK) ? postal : null),
      filter(postal => !!postal),
      switchMap((postal: string) => this.waskoService.getAvailableAddressList(postal)
        .pipe(
          tap(({addressList}) => {
            this.waskoService.saveAddressesInLocalStorage(postal, addressList)
          }),
          catchError(() => {
            this.addressForm.get('postalCode').patchValue('');
            return [];
          })
        )
      ),
      pluck('addressList'),
      tap(() => {
        if(this.addressForm.controls.postalCode.value !== this.addresses[0].postalCode) {
          this.addressForm.get('city').reset();
          this.addressForm.get('street').reset();
        }
      }),
      share()
    );


    function filterUniqueAndCreateOptions<T, Q extends keyof T>(array: T[], field: Q ): string[] {
      return array.reduce(
        (acc, curr) => !acc.includes(curr[field]) ? [...acc, curr[field]] : acc, []
      );
    }

    const autoFillValues = (list: string[], field: string) => {
      const control = this.addressForm.get(field);

      if (list.length < 1) {
        control.disable();
      } else {
        control.enable();
      }

      if (list.length === 1) {
        control.enable();
        control.setValue(list[0]);
      } else {
        control.setValue(null);
      }
    }

    this.$cityOptions = this.$addressList.pipe(
      filter(list =>  Array.isArray(list)),
      map(list => filterUniqueAndCreateOptions<BaseAddress, 'city'>(list, 'city')),
      tap(list => autoFillValues(list, 'city')),
      shareReplay(1)
    );

    this.$streetOptions = combineLatest([
      this.addressForm.get('city').valueChanges,
      this.$addressList
    ]).pipe(
      map(([city, addressList]) => addressList.filter(a => a.city === city)),
      filter(list =>  Array.isArray(list)),
      map((addressList) => filterUniqueAndCreateOptions<BaseAddress, 'street'>(addressList, 'street')),
      tap(list => autoFillValues(list, 'street')),
      shareReplay(1)
    );

    function asyncValidatorFactory (control: AbstractControl, $list: Observable<string[]>, errorPath: string) {
      return $list.pipe(
        take(1),
        map(list => {
          const error =  list.find(option => option === control.value) ? null : {[errorPath] : true} ;
          if (error) {
            control.markAsDirty();
          }
          return error;
        }),
      )
    }

    const postalCodeValidator: AsyncValidatorFn = (control: AbstractControl) => asyncValidatorFactory(control, this.$addressList.pipe( map((addressList) => filterUniqueAndCreateOptions<BaseAddress, 'postalCode'>(addressList, 'postalCode'))), '$$$SSE$$$pages.address.errors.postCodeNotFound');
    const cityAsyncValidator: AsyncValidatorFn = (control: AbstractControl) => asyncValidatorFactory(control, this.$cityOptions, '$$$SSE$$$pages.address.errors.cityNotFound');

    this.addressForm.get('postalCode').setAsyncValidators(postalCodeValidator);
    this.addressForm.get('city').setAsyncValidators(cityAsyncValidator);

    this.subscription = this.addressForm.statusChanges
      .pipe(
        distinctUntilChanged(),
        filter(status => status === 'VALID'),
        map(() => {
          const {postalCode, city} = this.addressForm.value;
          if(this.addressForm.value.street === "") {
            this.addressForm.get('street').patchValue(null)
          }
          return this.waskoService.getProvince(postalCode, city, this.addressForm.value.street);
        })
      )
      .subscribe(province => {
        if(province !== undefined) {
          this.addressForm.get('province').patchValue(province)
        }});
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  submitForm() {
    const request: UpdateAddressRequest = {
      type: 'ACTUAL',
      postalCode: this.addressForm.value.postalCode,
      postalCountry: 'PL',
      addressLine: '',
      locality: this.addressForm.value.city,
      administrativeArea: this.addressForm.value.province,
      apartmentNumber: this.addressForm.value.apartmentNumber,
      houseNumber: this.addressForm.value.houseNumber,
      street: this.addressForm.getRawValue().street,
      active: true
    }
    this.clientService.updateClientAddress(this.loan.clientId, request).pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
      this.alertService.notifySuccess(`Address updated for client ${this.loan.clientId}`);
      this.activeModal.close(true);
    });
  }
}
