<server-table
  [totalRecords]="response?.totalRecords"
  [totalPages]="response?.totalPages"
  [currentPage]="response?.currentPage"
  [pageSize]="searchQuery?.pageSize"
  [withFilterBlock]="true"
  (setPage)="refresh($event)"
  (refresh)="refresh()">
  <tbody filter-panel class="table-sm">
  <tr filter-element title="Dpd" type="number" property="dpd"></tr>
  </tbody>
  <div buttons class="mt-1">
    <span class="ml-2">
          <button appRequirePermission="admin.all, debt-step-configuration.edit"
                  class="btn btn-success btn-sm"
                  (click)="navigateTo(0)">
            <bi name="plus-circle"></bi>
            Create
          </button>
    </span>

    <span class="ml-2">
        <select class="form-control form-control-sm" #dcv  (change)="filterByVersion(dcv.value)">
              <option></option>
              <option *ngFor="let n of dcVersionCounter(10); let i = index; trackBy: trackByIndex" [value]="i+1">
                DC Version {{ i + 1 }}
              </option>
        </select>
    </span>
  </div>
  <table list-table class="table table-striped table-hover mt-1">
    <thead class="text-light bg-primary">
    <tr>
      <th></th>
      <th></th>
      <th sortable="id" (sort)="onSort($event)">ID</th>
      <th>Name</th>
      <th>DPD</th>
      <th>DLI</th>
      <th sortable="odpd" (sort)="onSort($event)">ODPD</th>
      <th>Promise Day</th>
      <th>DC version</th>
      <th>Is Enabled</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let debtConfig of response?.results; trackBy: trackById" (dblclick)="navigateTo(debtConfig.id)">
      <td></td>
      <td>
        <button type="submit"
                class="btn btn-outline-danger btn-sm"
                (click)="deleteConfig(debtConfig.id)"
                appRequirePermission="admin.all, debt-step-configuration.edit"
        >Delete</button>
      </td>
      <td>{{debtConfig.id}}</td>
      <td>{{debtConfig.debtStepName}}</td>
      <td>{{debtConfig.dpd}}</td>
      <td>{{debtConfig.dli}}</td>
      <td>{{debtConfig.odpd}}</td>
      <td>{{debtConfig.promiseDay}}</td>
      <td>{{debtConfig.dcVersion}}</td>
      <td>{{debtConfig.isEnabled}}</td>
    </tr>
    </tbody>
  </table>
</server-table>



