<div class="container-fluid row pt-1 pb-2">
  <p class="mt-2 ml-3">
    <b>Payment schedules</b>
  </p>
  <!-- region buttons -->
  <div class="col-md-12 mb-1">
        <span class="mr-1 float-right">
            <button class="btn btn-success btn-sm"
                    (click)="refresh()"
                    *ngIf="restructuringState?.stateType === restructuringStateType.RESTRUCTURING_INTENTION_ACTIVE">
              Refresh
            </button>
        </span>
  </div>
  <!-- endregion -->
  <div class="col-md-12">
    <server-table>
      <table list-table class="table table-striped table-hover table-sm">
        <thead class="text-light bg-primary">
        <tr>
          <th></th>
          <th>Amount</th>
          <th>Term</th>
          <th>Creation date</th>
          <th>Expiration date</th>
          <th>Activation amount</th>
          <th>Monthly payment amount</th>
          <th>First repayment date</th>
          <th>Restructuring activated</th>
          <th>Tolerance</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td></td>
          <td>{{activeIntention?.amountToRestructure}}</td>
          <td>{{activeIntention?.loanLength.value}} {{activeIntention?.loanLength.unit}}</td>
          <td>{{activeIntention?.creationDate}}</td>
          <td>{{activeIntention?.expirationDate}}</td>
          <td>{{activeIntention?.activationAmount}}</td>
          <td>{{activeIntention?.monthlyPaymentAmount}}</td>
          <td>{{activeIntention?.firstRepaymentDate}}</td>
          <td>{{activeIntention?.restructuringActivated}}</td>
          <td>{{activeIntention?.tolerance}}</td>
          <td></td>

        </tr>
        </tbody>
      </table>
    </server-table>

  </div>
</div>


