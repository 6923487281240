import { Component, OnInit } from '@angular/core';
import {
  AlertsService,
  BasicComponent,
  Utils
} from '@backoffice-monorepo/commons';
import { Application, FullLoan, RestrictedStatusesForTariffActivation } from '@twino/backoffice-api';
import { TariffActivationRequest, TariffResponseClient } from '../../api/tariff-client';
import { PlPiTariffClientService } from '../../services/pl-pi-tariff-client.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take, takeUntil, tap } from 'rxjs/operators';
import { TariffClientModalAllComponent } from '../tariff-client-modal-all/tariff-client-modal-all.component';
import { LoanSidebarComponent } from '@backoffice-monorepo/commons';

@Component({
  selector: 'backoffice-monorepo-tariff-client-sidebar',
  templateUrl: './tariff-client-sidebar.component.html',
  styleUrls: ['./tariff-client-sidebar.component.scss']
})
export class TariffClientSidebarComponent extends BasicComponent implements LoanSidebarComponent, OnInit {

  loan: FullLoan;
  tariffChangeIsPossible = false;
  application: Application | null;
  tariffs: TariffResponseClient[] = [];
  utils = Utils;
  hasPendingActivation: boolean;
  isAgreementDue: boolean;
  isRestrictedStatus: boolean;

  constructor(
    private tariffClientService: PlPiTariffClientService,
    private alertService: AlertsService,
    private modalService: NgbModal,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getTariffs();
  }

  activateTariff(tariffId: number) {
    const request: TariffActivationRequest = {
      clientId: this.loan.clientId,
      tariffId: tariffId
    }
    this.tariffClientService.activateTariff(request).pipe(
      takeUntil(this.$destroy)
    )
      .subscribe(() => {
        this.alertService.notifySuccess(`Tariff  ${tariffId} activated`);
        this.getTariffs();
      })
  }

  getTariffs() {
    this.tariffClientService.listTariffClient(this.loan.clientId).pipe(
      take(1),
      tap(res => {
        this.hasPendingActivation = res.tariffs.some(t => !t.isActive && t.isNextActive);
        this.isAgreementDue =  new Date(this.loan.currentAgreement.dueDate) < new Date();
        this.isRestrictedStatus =  RestrictedStatusesForTariffActivation.includes(this.loan.statusDetail);
      })
    ).subscribe(response => {
      this.tariffs = response.tariffs;
      this.tariffChangeIsPossible = response.tariffChangeIsPossible;
    })
  }

  trackById(index: number, item: TariffResponseClient) {
    return item.id;
  }

  showAll(tariff: TariffResponseClient) {
    const modalRef = this.modalService.open(TariffClientModalAllComponent, {size: 'lg'});
    modalRef.componentInstance.tariff = tariff;
  }

  cancelTariff(id: number) {
    this.tariffClientService.cancelTariff(this.loan.clientId).pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
        this.alertService.notifySuccess(`Tariff  ${id} canceled`);
        this.getTariffs();
      })
  }

  tariffCanBeActivated(tariff: TariffResponseClient): boolean {
    if (tariff.isNextActive || (tariff.isActive && !tariff.isNextActive) || this.isAgreementDue || this.isRestrictedStatus) {
      return false;
    } else if (!tariff.isActive && !tariff.isNextActive) {
      return !this.hasPendingActivation;
    } else {
      return true;
    }
  }

}
