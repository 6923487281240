import { Component, ComponentFactoryResolver, Inject, OnDestroy, OnInit, Type, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AMOUNT,
  BOOLEAN,
  CLIENT,
  ClientService,
  ConfirmDialogueComponent,
  CountryViewComponent,
  CountryLoanButtonViewDirective,
  DATE, DISCOUNT_PERCENT,
  draggableModalOptions,
  fromStringObservable,
  ID,
  LoanButtonsViewComponentResolver,
  LoanService,
  NamedComponent,
  NUMBER,
  STRING,
  TransformInstructions,
  TransformInstructionsBuilder,
  OptionalCoreComponent,
  LoanExtensionFeesComponentResolver,
  RATE_PER_MONTH_AS_PERCENT
} from '@backoffice-monorepo/commons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FullLoan, ScheduleItem } from '@twino/backoffice-api';
import {
  LoanLibComponentRecord,
  LoanSidebarLibComponentResolver
} from '../../../../../../../commons/src/lib/services/loan-sidebar-lib-component-resolver';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { LoanAppliedDiscountsComponent } from '../loan-applied-discounts/loan-applied-discounts.component';
import {
  LoanSidebarComponentResolver,
  LoanSpecificComponentRecord
} from '@backoffice-monorepo/commons';
import { LoansRefreshService } from '../../services/loans-refresh-services';
import { LoanWriteOffComponent } from '../loan-write-off/loan-write-off.component';
import { take, takeUntil } from 'rxjs/operators';
import { OptionalCoreComponentResolver } from '../../../../../../../commons/src/lib/resolvers/optional-core-component.resolver';
import { LoanDebtComponent } from '../loan-debt/loan-debt.component';
import { LoanViewFieldsComponentResolver } from '@backoffice-monorepo/commons';
import {
  CurrentLoanAgreementComponentResolver
} from '@backoffice-monorepo/commons';

@Component({
  selector: 'backoffice-monorepo-loan',
  templateUrl: './loan.component.html',
  styleUrls: ['./loan.component.scss']
})
export class LoanComponent extends NamedComponent implements OnInit, OnDestroy {

  @ViewChild(CountryLoanButtonViewDirective, {static: true})
  countryLoanButtonView!: CountryLoanButtonViewDirective;
  countryLoanSidebarComponents: LoanSpecificComponentRecord[];

  private reloadLoanModel: Subscription;
  private switchLoanTab: Subscription;

  id: number;
  loan?: FullLoan | null;
  active: any;
  private clientNameSubject$ = new ReplaySubject<string>(1)
  clientName: Observable<string> = this.clientNameSubject$.asObservable();
  libLoanSidebarComponents: LoanLibComponentRecord[];
  appliedDiscountsNumber: number;
  countryOptionalCoreComponents: OptionalCoreComponent[];
  loanViewFieldsComponentType: Type<CountryViewComponent>;
  loanExtensionFeesComponentType: Type<CountryViewComponent>;
  scheduleItems: ScheduleItem[] | null | undefined;
  currentLoanAgreementComponentType: Type<CountryViewComponent>;

  loanTransformations: TransformInstructions = TransformInstructionsBuilder.build(
    [
      ['', ['Client Name', () => fromStringObservable(this.clientName)]],
      ['id', ['Id', ID]],
      ['loanNumber', ['Number', STRING]],
      ['clientId', ['Client', CLIENT]],
      ['status', ['Status', STRING]],
      ['statusDetail', ['Status Detail', STRING]],
      ['mainStartDate', ['Main Start Date', () => this.loan.mainAgreement.startDate.toString().asString()]],
      ['dueDate', ['Due Date', () => this.loan.currentAgreement.dueDate.toString().asString()]],
      ['dpd', ['Days Past Due', NUMBER]],
      ['overallDaysPastDue', ['Overall Days Past Due', NUMBER]],
      ['currentDebtStep', ['Current Debt Step', NUMBER]],
      ['agreementCount', ['Agreement Count', () => this.loan.agreements.length.toString().asString()]],
      ['lenderId', ['Lender Id', NUMBER]],
      ['penaltyGenerationActive', ['Penalty Generation', BOOLEAN]],
      ['dcVersion', ['DC version', NUMBER]],
      ['created', ['Created', DATE]],
      ['additionalAmountAvailability', ['Additional amount available', () => this.loan.additionalAmountAvailability.isAvailable.toString().asString()]],
      ['brand', ['Brand', STRING]],
    ]
  );


  loanAmountsTransformations: TransformInstructions = TransformInstructionsBuilder.build(
    [
      ['openAmount', ['Open Amount', AMOUNT]],
      ['principalBalance', ['Principal Balance', AMOUNT]],
      ['openInterest', ['Open Interest', AMOUNT]],
      ['openPenalty', ['Open Penalty', AMOUNT]],
      ['openFees', ['Open Fees', AMOUNT]],
    ]
  );

  loanNextAmountsTransformations: TransformInstructions = TransformInstructionsBuilder.build(
    [
      ['totalRepaymentAmount', ['Total repayment amount', AMOUNT]],
      ['nextRepaymentAmount', ['Next repayment amount', AMOUNT]],
      ['nextRepaymentDate', ['Next repayment date', DATE]],
    ]
  );

  currentAgreementTransformations: TransformInstructions = TransformInstructionsBuilder.build(
    [
      ['type', ['Type', ID]],
      ['amount', ['Amount', AMOUNT]],
      ['startDate', ['Start date', DATE]],
      ['signDate', ['Sign date', DATE]],
      ['closedDate', ['Close date', DATE]],
      ['interestRatePerYear', ['Interest rate per year', STRING]],
      ['annualPercentageRate', ['Annual percentage rate', STRING]],
      ['penaltyGracePeriodDays', ['Penalty grace period days', NUMBER]],
      ['penaltyGenerationPeriodBeginningDays', ['Penalty generation period beginning days', NUMBER]],
      ['maxPenaltyCoeff', ['Max penalty coeff.', NUMBER]],
      ['term', ['Term', () => `${this.loan.currentAgreement.term.loanLength?.value} ${this.loan.currentAgreement.term.loanLength?.unit}`.asString()]],
      ['extensionTerm', ['Extension term', STRING]],
      ['appliedDiscounts', ['Discount percent', DISCOUNT_PERCENT]],
      ['commissionRateParameters', ['Loan commission rate per month', RATE_PER_MONTH_AS_PERCENT]],
      ['loanPurpose', ['Loan purpose', STRING]],
      ['dueDate', ['Due date', DATE]],
    ]
  );

  constructor(
    activatedRoute: ActivatedRoute,
    private loanService: LoanService,
    private clientService: ClientService,
    private modalService: NgbModal,
    @Inject('LoanButtonsViewComponentResolver') public loanButtonsViewComponentResolver: LoanButtonsViewComponentResolver,
    @Inject('LoanSidebarLibComponentResolver') public loanSidebarLibComponentResolver: LoanSidebarLibComponentResolver,
    @Inject('LoanSidebarComponentResolver') public loanSidebarComponentResolver: LoanSidebarComponentResolver,
    private componentFactoryResolver: ComponentFactoryResolver,
    private loanRefreshService: LoansRefreshService,
    @Inject('OptionalCoreComponentResolver') public optionalCoreComponents: OptionalCoreComponentResolver,
    @Inject('LoanViewFieldsComponentResolver') loanViewFieldsComponentResolver: LoanViewFieldsComponentResolver,
    @Inject('LoanExtensionFeesComponentResolver') loanExtensionFeesComponentResolver: LoanExtensionFeesComponentResolver,
    @Inject('CurrentLoanAgreementComponentResolver') currentLoanAgreementComponentResolver: CurrentLoanAgreementComponentResolver,
  ) {
    super(activatedRoute);
    this.libLoanSidebarComponents = this.loanSidebarLibComponentResolver.resolve();
    this.countryLoanSidebarComponents = this.loanSidebarComponentResolver.resolve();
    this.countryOptionalCoreComponents = this.optionalCoreComponents.resolve();
    this.loanViewFieldsComponentType = loanViewFieldsComponentResolver.resolve();
    this.loanExtensionFeesComponentType = loanExtensionFeesComponentResolver.resolve();
    this.currentLoanAgreementComponentType = currentLoanAgreementComponentResolver.resolve();
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.params['id'];
    this.refresh();
    this.reloadLoanModel = this.loanRefreshService.reloadLoanModel$.subscribe(() => {
      this.refresh();
    })
    this.switchLoanTab = this.loanRefreshService.switchLoanTab$.subscribe(() => {
      this.active = "Payment schedule";
    })
  }

  ngOnDestroy() {
    this.reloadLoanModel.unsubscribe();
  }

  getName(): string {
    return `Loan ${this.id || ''}`;
  }

  voidLoan() {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = "Void Loan";
    modalRef.componentInstance.content = "Voiding loan cannot be undone!";
    modalRef.result.then(
      (result) => {
        if (result === true) this.loanService.voidLoan(this.loan?.id).subscribe();
      }, () => {}
    );
  }

  writeOff() {
    const modalRef = this.modalService.open(LoanWriteOffComponent, draggableModalOptions);
    modalRef.componentInstance.loanId = this.loan.id;
  }

  viewAppliedDiscounts() {
    const modalRef = this.modalService.open(LoanAppliedDiscountsComponent, draggableModalOptions);
    modalRef.componentInstance.appliedDiscounts = this.loan.currentAgreement.appliedDiscounts;
    modalRef.componentInstance.loanId = this.loan.id;
  }

  refresh() {
    this.loanService.getLoan(this.id).pipe(
        takeUntil(this.$destroy)
      )
      .subscribe(loan => {
        this.loan = loan;
        this.scheduleItems = loan?.currentAgreement?.scheduleItems;
        this.appliedDiscountsNumber = Object.keys(loan?.currentAgreement.appliedDiscounts).length;
        this.loadCountrySpecificButtons()
        this.clientService.getClient(this.loan.clientId)
          .pipe(
            take(1)
          ).subscribe((client) => {
          this.clientNameSubject$.next(`${client.firstName} ${client.lastName}`);
        })
      });
  }

  private loadCountrySpecificButtons() {
    const componentType = this.loanButtonsViewComponentResolver.resolve();
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);

    const viewContainerRef = this.countryLoanButtonView.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent<CountryViewComponent>(componentFactory);
    componentRef.instance.data = this.loan;
  }

  showLoanDebtComponent(): boolean {
    return this.countryOptionalCoreComponents.includes(LoanDebtComponent);
  }

}
