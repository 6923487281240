<div class="container-fluid row flex-column pt-1 pb-2">
  <p class="mt-2 ml-3">
    <b>Client tariff plans</b>
  </p>
  <div class="tariff-selection-container">

    <div class="tariff-container" *ngFor="let tariff of tariffs">
      <div class="tariff">
        <div class="tariff-data-container">
          <div class="tariff-title">{{tariff.name}}</div>
          <div class="tariff-price">{{tariff.monthlyPrice}} <span class="per-month">zl</span></div>
          <div class="fees-container">
            <div class="fee">
              <div class="fee-name">Is active</div>
              <div class="fee-price">{{tariff.isActive}}</div>
            </div>
            <div class="fee">
              <div class="fee-name">Is next active</div>
              <div class="fee-price">{{tariff.isNextActive}}</div>
            </div>
            <div class="fee" *ngFor="let fee of tariff.monthlyFees | keyvalue">
              <div class="fee-name">{{utils.enumValueToLabel(fee.key)}}</div>
              <div class="fee-price">{{fee.value.monthlyFee}} zl</div>
            </div>
          </div>
        </div>
        <div *ngIf="!tariff.isActive && tariff.isNextActive; else activateBtn" class="button-container">
          <button class="button select-tariff  btn-danger" (click)="cancelTariff(tariff.id)" [disabled]="!tariffChangeIsPossible && tariff.isActive === true">
            Cancel Activation
          </button>
        </div>
        <ng-template #activateBtn>
          <div *ngIf="tariffCanBeActivated(tariff)" class="button-container">
            <button class="button select-tariff" (click)="activateTariff(tariff.id)" [disabled]="!tariffChangeIsPossible && tariff.isActive === true">
              Activate
            </button>
          </div>
        </ng-template>
        <div class="button-container">
          <button type="button" class="button show-tariff-info" (click)="showAll(tariff)">Show all</button>
        </div>
      </div>
    </div>
  </div>
</div>


