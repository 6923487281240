import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonsModule } from '@backoffice-monorepo/commons';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbDatepickerModule, NgbDropdownModule, NgbNavModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { LoanSaleRoutingModule } from './loan-sale-routing.module';
import { LoanSaleDataListComponent } from './components/loan-sale-data-list/loan-sale-data-list.component';
import { LoanPresaleDataListComponent } from './components/loan-presale-data-list/loan-presale-data-list.component';
import { LoanSellPackageComponent } from './components/loan-sell-package/loan-sell-package.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BootstrapIconsModule } from 'ng-bootstrap-icons';
import { LoanSaleComponent } from './components/loan-sale/loan-sale.component';
import { ChangeLoanPortfolioComponent } from './components/change-loan-portfolio/change-loan-portfolio.component';

@NgModule({
  declarations: [LoanSaleDataListComponent, LoanPresaleDataListComponent, LoanSellPackageComponent, LoanSaleComponent, ChangeLoanPortfolioComponent],
  imports: [
    CommonModule,
    LoanSaleRoutingModule,
    CommonsModule,
    NgbNavModule,
    TranslateModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    DragDropModule,
    BootstrapIconsModule,
    NgbPaginationModule,
    NgbDropdownModule
  ]
})
export class LoanSaleModule { }
