import { Injectable } from '@angular/core';
import { BaseService, ErrorsService } from '@backoffice-monorepo/commons';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RefundTariffFeeRequest, WriteOffTariffFeeRequest } from '../api/tariff-write-off-refund';
import { SearchQuery, SearchResponse, TransactionEntry } from '@twino/backoffice-api';

@Injectable({
  providedIn: 'root'
})
export class PlPiTariffWriteOffRefundService extends BaseService  {
  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }

  listWriteOffTransactions(loanId: number, searchQuery: SearchQuery): Observable<SearchResponse<TransactionEntry>> {
    return this.post(`/api/pl/tariff/write-off/${loanId}/transactions`, searchQuery);
  }

  writeOffTariffFee(request: WriteOffTariffFeeRequest): Observable<unknown> {
    return this.post(`/api/pl/tariff/write-off`, request);
  }

  listRefundTransactions(loanId: number, searchQuery: SearchQuery): Observable<SearchResponse<TransactionEntry>> {
    return this.post(`/api/pl/tariff/refund/${loanId}/transactions`, searchQuery);
  }

  refundTariffFee(request: RefundTariffFeeRequest): Observable<unknown> {
    return this.post(`/api/pl/tariff/refund`, request);
  }

}
