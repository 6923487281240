import { Component, Host, OnDestroy, OnInit } from '@angular/core';
import { FilteringService, ListComponent, SalePartnerService } from '@backoffice-monorepo/commons';
import { LoanSalePartnerResponse, SearchQueryAware } from '@twino/backoffice-api';
import { ActivatedRoute, Router } from '@angular/router';
import { SalePartnersRefreshService } from '../../services/sale-partners-refresh.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'backoffice-monorepo-sale-partners-list',
  templateUrl: './sale-partners-list.component.html',
  styleUrls: ['./sale-partners-list.component.css'],
  providers: [
    {
      provide: FilteringService,
      useFactory: (listService: SearchQueryAware<LoanSalePartnerResponse>) => new FilteringService(listService),
      deps: [SalePartnerService]
    }
  ]
})
export class SalePartnersListComponent extends ListComponent<LoanSalePartnerResponse> implements OnInit, OnDestroy {

  reloadSalePartnersModel: Subscription;

  constructor(
    @Host() filteringService: FilteringService<LoanSalePartnerResponse>,
    protected router: Router,
    activatedRoute: ActivatedRoute,
    private salePartnersRefreshService: SalePartnersRefreshService
  ) {
    super(activatedRoute, filteringService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.reloadSalePartnersModel = this.salePartnersRefreshService.reloadSalePartnersModel$.subscribe(() => super.ngOnInit());
  }

  navigateTo(id: string | number) {
    this.router.navigate([`/sale-partners/${id}`]);
  }

  getName() {
    return 'Sale partners';
  }

  ngOnDestroy(): void {
    this.reloadSalePartnersModel.unsubscribe();
  }
}
