import { Injectable } from '@angular/core';
import { BaseService, ErrorsService } from '@backoffice-monorepo/commons';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  CalculateRestructuringMonthlyAmountRequest, CalculateRestructuringMonthlyAmountResponse,
  CreateRestructuringIntentionRequest,
  RestructuringAvailabilityResponse,
  RestructuringIntentionResponse, RestructuringStateResponseBackoffice
} from '../api/restructuring';

@Injectable({
  providedIn: 'root'
})
export class PlPiRestructuringService extends BaseService  {
  constructor(
    protected http: HttpClient,
    protected errorsService: ErrorsService
  ) {
    super(http, errorsService);
  }


  getCurrentRestructuringState(loanId: number): Observable<RestructuringStateResponseBackoffice> {
    return this.get(`/api/pl/loans/restructuring/${loanId}/current-state`);
  }

  createRestructuringIntention(request: CreateRestructuringIntentionRequest): Observable<RestructuringIntentionResponse> {
    return this.post(`/api/pl/loans/restructuring/intention`, request);
  }

  getActiveRestructuringIntention(loanId: number): Observable<RestructuringIntentionResponse> {
    return this.get(`/api/pl/loans/restructuring/intention/active/${loanId}`);
  }

  calculateMonthlyAmount(request: CalculateRestructuringMonthlyAmountRequest): Observable<CalculateRestructuringMonthlyAmountResponse> {
    return this.post(`/api/pl/loans/restructuring/calculate-monthly-amount`, request);
  }

  getRestructuringAvailability(loanId: number): Observable<RestructuringAvailabilityResponse> {
    return this.get(`/api/pl/loans/restructuring/availability/${loanId}`);
  }

}
