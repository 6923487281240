import { Term } from './term';
import {
  CommissionRateParameters,
  CurrencySettings,
  DiscountShortInfo,
  DiscountType,
  ScheduleItem,
  SearchModel
} from '@twino/backoffice-api';

export interface Agreement extends SearchModel {
  id: number
  loanId: number
  offerId: number
  loanApplicationId: number
  amount: number
  newAmount: number
  term: Term
  extensionTerm?: Term
  status: string
  type: string
  startDate: Date
  dueDate: Date
  maxPenaltyCoeff: number
  penaltyRatePerDay: number
  penaltyGracePeriodDays: number
  penaltyGenerationPeriodBeginningDays: number
  discountPercent: number
  interestRatePerYear: number
  annualPercentageRate: number
  scheduleItems: ScheduleItem[]
  current: boolean
  signDate?: Date
  closedDate?: Date
  loanPurpose?: string
  currencySettings?: CurrencySettings
  lenderId?: number
  statusDetail?: string
  appliedDiscounts: Map<DiscountType, DiscountShortInfo>
  commissionRateParameters: CommissionRateParameters[]
}

export enum LoanAgreementType {
  MAIN = 'MAIN',
  ADDITIONAL_AMOUNT = 'ADDITIONAL_AMOUNT',
  EXTENSION = 'EXTENSION',
  DEBT_COLLECTION = 'DEBT_COLLECTION',
  RENOUNCEMENT = 'RENOUNCEMENT',
  TERMINATION = 'TERMINATION',
  CDS = 'CDS',
  DC_TERMINATION = 'DC_TERMINATION',
  PROLONGATION = 'PROLONGATION',
  DECEASED = "DECEASED",
  AML_TERMINATION = "AML_TERMINATION",
  POLICE_TERMINATION = "POLICE_TERMINATION",
  RESTRUCTURING = "RESTRUCTURING",
}
