<span class="mr-2 ml-3" *ngIf="showButtons">
  <button appRequirePermission="admin.all,loan.admin,loan.termination" class="btn btn-danger btn-sm" (click)="closeLoan()">
    <bi name="x-square"></bi>
    Close loan
  </button>
</span>

<span class="mr-2 ml-3" *ngIf="showButtons">
  <button appRequirePermission="admin.all,loan.admin"  class="btn btn-danger btn-sm" (click)="pauseLoan()">
    <bi name="x-square"></bi>
    Pause Loan
  </button>
</span>

<span class="mr-2 ml-3" *ngIf="showResumeButton">
  <button appRequirePermission="admin.all,loan.admin"  class="btn btn-danger btn-sm" (click)="resumeLoan()">
    <bi name="x-square"></bi>
    Resume Loan
  </button>
</span>

<span class="mr-2 ml-3" *ngIf="showButtons">
  <button appRequirePermission="admin.all,loan.admin,loan.renounce"  class="btn btn-danger btn-sm" (click)="setRenouncement()">
    <bi name="x-square"></bi>
    Renouncement
  </button>
</span>

<span class="mr-2 ml-3" *ngIf="showButtons">
  <button appRequirePermission="admin.all,loan.admin"
          class="btn btn-danger btn-sm"
          (click)="mtpAbatement()">
    <bi name="x-square"></bi>
    MTP Abatement
  </button>
</span>

<span class="mr-2 ml-3" *ngIf="showButtons">
  <button appRequirePermission="admin.all,loan.admin,transfer.create,payment.create-manual"
          class="btn btn-success btn-sm"
          (click)="sendTransferToClient()">
    <bi name="arrow-up-right"></bi>
    Send Transfer
  </button>
</span>

<span class="mr-2 ml-3" *ngIf="showButtons && this.data.additionalAmountAvailability.isAvailable === true">
  <button appRequirePermission="admin.all,loan.admin,loan.increase-limit"
          class="btn btn-success btn-sm"
          (click)="increaseLimit()">
    <bi name="arrow-up"></bi>
    Increase limit
  </button>
</span>

<span class="mr-2 ml-3" *ngIf="showButtons && restructuringState?.stateType === restructuringStateType.RESTRUCTURING_AVAILABLE">
  <button appRequirePermission="admin.all,loan.admin"
          class="btn btn-success btn-sm"
          (click)="paymentSchedule()">
    <bi name="pencil-square"></bi>
    Apply for payment schedule
  </button>
</span>

<span class="mr-2 ml-3" *ngIf="showButtons && restructuringState?.stateType === restructuringStateType.RESTRUCTURING_INTENTION_ACTIVE">
  <button appRequirePermission="admin.all,loan.admin"
          class="btn btn-success btn-sm"
          (click)="paymentScheduleTab()">
    <bi name="pencil-square"></bi>
    Check payment schedule
  </button>
</span>
