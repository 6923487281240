<form [formGroup]="transferAddForm" (ngSubmit)="submitTransferForm()" *ngIf="!showConfirmationForm">
  <div class="modal-header">
    <h4 class="modal-title">Add outgoing transfer</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
      <div class="form-group row">
        <label for="amount" class="col-sm-4 control-label">Amount<sup class="text-danger">*</sup></label>
        <div class="col-sm-8">
          <input type="text" class="form-control form-control-sm" id="amount" formControlName="amount" name="amount">
        </div>
      </div>
      <ul class="list-group mt-2" *ngIf="enableAddButton">
        <li class="list-group-item">Cost: {{chargeableInfo.chargeableOperationFeeInfo.calculatedFeeAmount}}</li>
      </ul>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('Close click')">Close</button>
    <button type="submit" class="btn btn-outline-danger" [disabled]="!enableAddButton">Add</button>
    <button type="button" class="btn btn-outline-warning" [disabled]="transferAddForm.invalid" (click)="getChargeableOperationInfo()">Calculate</button>
  </div>
</form>

<form [formGroup]="otpConfirmForm" (ngSubmit)="confirmOtp()" *ngIf="showConfirmationForm">
  <div class="modal-header">
    <h4 class="modal-title">Confirm transfer</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label for="confirmationCode" class="col-sm-4 control-label">Confirmation code<sup class="text-danger">*</sup></label>
      <div class="col-sm-8">
        <input type="text" class="form-control form-control-sm" id="confirmationCode" formControlName="confirmationCode" name="confirmationCode">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('Close click')">Close</button>
    <button type="submit" class="btn btn-outline-warning" [disabled]="!otpConfirmForm.valid"  *ngIf="showConfirmationForm">Confirm</button>
  </div>
</form>








