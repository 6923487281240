import { Component, Input } from '@angular/core';
import { AlertsService, BasicComponent, TwinoDateAdapterService } from '@backoffice-monorepo/commons';
import { PlPiDebtCollectionStagesService } from '../../modules/debt-collection-stages/services/pl-pi-debt-collection-stages.service';
import { NgbActiveModal, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { ChangeManualDcStatusRequest, LoanDebtStepManualStatus, RemoveFromSaleRequest } from '../../modules/debt-collection-stages/api/debt-stage';

@Component({
  selector: 'backoffice-monorepo-pl-pi-loan-manual-debt-status',
  templateUrl: './pl-pi-loan-manual-debt-status.component.html',
  styleUrls: ['./pl-pi-loan-manual-debt-status.component.scss'],
  providers: [
    {provide: NgbDateAdapter, useClass: TwinoDateAdapterService}
  ]
})
export class PlPiLoanManualDebtStatusComponent extends BasicComponent {

  @Input()
  loanId: number;
  @Input()
  mode: LoanDebtStepManualStatus;
  dateTimeValue: string;

  constructor(
    private debtCollectionStagesService: PlPiDebtCollectionStagesService,
    public activeModal: NgbActiveModal,
    private alertService: AlertsService,
  ) {
    super();
  }

  submitForm() {
    this.setManualStatus().pipe(
      takeUntil(this.$destroy)
    ).subscribe(() => {
      this.alertService.notifySuccess(`Loan is marked as ${this.mode}!`);
      this.activeModal.close(true);
    });
  }

  setManualStatus() {
    const manualDebtStatusRequest: ChangeManualDcStatusRequest = {
      onTime: this.dateTimeValue,
    }

    switch (this.mode) {
      case LoanDebtStepManualStatus.POLICE: {
        return this.debtCollectionStagesService.markLoanAsPolice(this.loanId, manualDebtStatusRequest)
      }
      case LoanDebtStepManualStatus.DECEASE: {
        return this.debtCollectionStagesService.markLoanAsDeceased(this.loanId, manualDebtStatusRequest)
      }
      case LoanDebtStepManualStatus.PRE_BUY_BACK: {
        return this.debtCollectionStagesService.preBuyBack(this.loanId, manualDebtStatusRequest)
      }
      case LoanDebtStepManualStatus.INSOLVENCY: {
        return this.debtCollectionStagesService.markLoanAsInsolvency(this.loanId, manualDebtStatusRequest)
      }
      case LoanDebtStepManualStatus.REMOVE_FOR_SALE: {
        const request: RemoveFromSaleRequest = {
          loanIds: [this.loanId],
          onDate: this.dateTimeValue.substr(0,10)
        }
        return this.debtCollectionStagesService.removeFromSale(request)
      }
    }
  }
}
