export interface WriteOffTariffFeeRequest {
  bookingDate: Date
  transactionId: number
}

export interface RefundTariffFeeRequest {
  bookingDate: Date
  transactionId: number
}

  export enum TariffTransactionChangeMode {
  WRITE_OFF = 'WRITE_OFF',
  REFUND = 'REFUND'
}
