import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil, tap } from 'rxjs/operators';
import { LoanSalePortfolioService } from '@backoffice-monorepo/commons';
import { LoanSaleDataResponse, LoanSaleDataResponseSearchResult, SearchQuery } from '@twino/backoffice-api';
import { ConfirmDialogueComponent, NamedComponent } from '@backoffice-monorepo/commons';
import { ChangeLoanPortfolioComponent } from '../change-loan-portfolio/change-loan-portfolio.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoanSaleRefreshService } from '../../services/loan-sale-refresh.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'backoffice-monorepo-loan-sale',
  templateUrl: './loan-sale.component.html',
  styleUrls: ['./loan-sale.component.scss']
})
export class LoanSaleComponent extends NamedComponent implements OnInit {
  portfolioId: number;
  searchQuery: SearchQuery = SearchQuery.fromBaseQuery();
  response$: BehaviorSubject<LoanSaleDataResponseSearchResult> = new BehaviorSubject(null);


  constructor(public activatedRoute: ActivatedRoute,
              private modalService: NgbModal,
              private loanSalePortfolioService: LoanSalePortfolioService,
              private loanSaleRefreshService: LoanSaleRefreshService
  ) {
    super(activatedRoute);
  }

  getName(): string {
    return `Loan Sale ${this.portfolioId || ''}`;
  }

  ngOnInit(): void {
    this.portfolioId = +this.activatedRoute.snapshot.params['id'];
    this.getPortfolioLoans();

  }

  refresh(page = 0) {
    if (page !== 0) {
      this.searchQuery.page = page;
    }
    this.getPortfolioLoans();
  }
  getPortfolioLoans() {
    this.loanSalePortfolioService.getLoansByPortfolioId(this.portfolioId, this.searchQuery).pipe(
      tap(response => {
        this.response$.next(response)
      }),
    ).subscribe()
  }


  removeFromPortfolio(loanId: number) {
    const modalRef = this.modalService.open(ConfirmDialogueComponent);
    modalRef.componentInstance.header = `Remove loan from the portfolio`;
    modalRef.componentInstance.content = `Are you sure you want to delete loan #${loanId} from the portfolio?`;
    modalRef.result.then(
      (result) => {
        if (result === true) {
          this.loanSalePortfolioService.removeLoansFromPortfolio({loanIds: [loanId]}).pipe(
            takeUntil(this.$destroy)
          ).subscribe(() => {
            this.loanSaleRefreshService.reloadLoanSaleList();
            this.refresh();
          });
        }
      }
    );

  }
  moveToAnotherPortfolio(loan: LoanSaleDataResponse) {
    const modalRef = this.modalService.open(ChangeLoanPortfolioComponent);
    modalRef.componentInstance.loan = loan;
    modalRef.result.then(
      (result) => {
        if (result === true) {
          this.loanSaleRefreshService.reloadLoanSaleList();
          this.refresh();
        }
      }
    );
  }
}
